import { CustomModal, StepsProgress } from "globalComponents";
import useManageMentorModal from "./useManageMentorModal";
import "./styles.scss";

const ManageMentorModal = ({ open, onCancel }) => {
  const { loading, activeStep, onNextStep, stepsComponent } =
    useManageMentorModal({
      onCancel,
    });

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      width={activeStep === 1 ? 500 : 800}
    >
      {stepsComponent[activeStep]}
      <div className="manage-mentor-footer">
        <StepsProgress
          total={2}
          activeStep={activeStep}
          onNextStep={onNextStep}
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};

export default ManageMentorModal;
