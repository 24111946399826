import { useForm } from "antd/es/form/Form";
import { useImperativeHandle } from "react";

const useAddNewCourses = ({ ref }) => {
  const [form] = useForm();

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit: () => form.validateFields(),
    }),
    [form]
  );

  return { form };
};

export default useAddNewCourses;
