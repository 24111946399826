import { Typography } from "antd";
import "./styles.scss";

const AdminListingCard = ({
  title = "",
  listing = [],
  handleViewClick,
  addNewTitleBtnProps,
  actionTitle = "",
}) => {
  return (
    <div className="admin-listing-card">
      <div className="admin-listing-card-header">
        <Typography.Title
          level={5}
          className="primary-text-color font-semibold"
        >
          {title}
        </Typography.Title>
      </div>
      <div className="admin-listing-cards-wrapper">
        {listing.map((item, index) => (
          <div className="alc-row" key={index}>
            <Typography.Text className="font-bold m-0">
              {item?.label}
            </Typography.Text>
            <Typography.Text
              type="text"
              className="primary-text-color m-0 text-small font-semibold cursor-pointer"
              onClick={() => handleViewClick(item?.href)}
            >
              {actionTitle}
            </Typography.Text>
          </div>
        ))}
      </div>
      <div className="admin-listing-card-footer">
        <Typography.Text
          className="primary-text-color text-small font-semibold cursor-pointer"
          onClick={addNewTitleBtnProps?.onClick}
        >
          {addNewTitleBtnProps?.title} {">>"}
        </Typography.Text>
      </div>
    </div>
  );
};
export default AdminListingCard;
