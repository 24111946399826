import { Button } from "antd";
import Paragraph from "antd/es/typography/Paragraph";

const PrimaryButton = ({
  text,
  children,
  textClass = "",
  className, onClick,
  ...props
}) => (
  <Button onClick={onClick} style={{ backgroundColor: '#57C1EB'}} type={"primary"} className={`gc-primary-btn ${className}`}>
    {text ? (
      <Paragraph style={{color: 'white'}} className={`gc-primary-btn-text -mb-3-imp ${textClass}`}>
        {text}
      </Paragraph>
    ) : (
      children
    )}
  </Button>
);

export default PrimaryButton;
