import { Typography } from "antd";
import { educatorThumbnailSvg } from "assets/directory/svgs/educator";

const EducatorLanding = () => (
  <div className={"educator-appointment-container"}>
    <Typography.Title className={"eda-title"} level={4}>
      {`Make Sure You’re Utilizing What You’re Learning Correctly`}
    </Typography.Title>
    <div className={"edi-thumbnail-container"}>{educatorThumbnailSvg}</div>
  </div>
);

export default EducatorLanding;
