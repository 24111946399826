import { Typography } from 'antd'
import { MapCardContainer } from './components/MapCardContainer'
import { MapContainer } from './components/MapContainer'
import './styles.scss'
const DashboardPage = () => {
  return (
    <div className={'dashboard-page-container'}>
      <div className={'dp-title-container'}>
        <Typography.Title style={{ color: "black"}} level={3}>{'Our International Community'}</Typography.Title>
      </div>
      <div className={'map-detail-card-container'}>
        <MapContainer />
        <MapCardContainer />
      </div>
    </div>
  )
}

export default DashboardPage
