import { Form, Input, Typography } from "antd";

const MyInfo = () => {
  return (
    <div className="my-info-wrapper">
      <Form
        disabled
        layout="vertical"
        initialValues={{
          username: "John",
          email: "john@email.com",
        }}
      >
        <Form.Item label="Username" name="username">
          <Input bordered={false} />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input bordered={false} />
        </Form.Item>
        <Typography.Text className="edit-icon font-bold">Edit Info</Typography.Text>
      </Form>
    </div>
  );
};

export default MyInfo;
