import { CheckCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { Loader } from "globalComponents";
import { getServerUrlAttached } from "utils";

export const EducatorsList = ({
  instructors,
  selectedInstructorId,
  onSelect,
  getInstructorsApiLoading,
}) => {

  return (
    <div className={"educators-list-container"}>
      {getInstructorsApiLoading ? (
        <Loader />
      ) : (
        (instructors ?? []).map((val, index) => (
          <EducatorItem
            key={index}
            value={val}
            selected={+selectedInstructorId === val.instructors?.id}
            onSelect={onSelect}
          />
        ))
      )}
    </div>
  );
};

const EducatorItem = ({ value, selected, onSelect }) => (
  <div
    className={`educator-list-item ${
      selected ? "educator-list-item-selected" : ""
    }`}
    onClick={() => onSelect(value)}
  >
    <img
      className={"edl-image"}
      src={value?.photo}
      alt="educator"
    />
    <div className={"educator-details min-width-0"}>
      <div className={"educator-detail-info-container"}>
        <div className={"educator-title df-row"}>
          <Typography.Paragraph
            ellipsis={{ tooltip: true }}
            className={"educator-title-text -mb-3-imp"}
          >
            {value?.name}
          </Typography.Paragraph>
          <CheckCircleFilled className={"educator-title-icon platinum"} />
        </div>
        {value?.company && (
            <Typography.Paragraph className={"educator-desc-text"}>
              {value?.company}
            </Typography.Paragraph>
        )}
      </div>
      <Typography.Paragraph className={"educator-call-text"}>
        {"Get on a Call"}
      </Typography.Paragraph>
    </div>
  </div>
);
