import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Space, Typography, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import { folder } from "assets";
import { forwardRef, Fragment, useImperativeHandle } from "react";
import { formRules } from "./formRules";

const AddCourseContent = (_, ref) => {
  const [form] = useForm();

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit: () => form.validateFields(),
    }),
    [form]
  );
  return (
    <Form
      form={form}
      className="form-style-1"
      initialValues={{
        modules: [
          {
            name: "",
            description: "",
          },
        ],
      }}
    >
      <Typography.Title className="font-bold" level={4}>
        Add A New Course
      </Typography.Title>

      <Form.List name={"modules"}>
        {(fields, { add, remove }, { errors }) => (
          <Row gutter={[0, 30]}>
            {fields.map(({ key, name, ...restField }, moduleIndex) => (
              <Col xs={24} key={key}>
                <Space className="mb-2">
                  <Typography.Title
                    level={5}
                    className="primary-text-color m-0"
                  >
                    Module {moduleIndex + 1}
                  </Typography.Title>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  ) : null}
                </Space>

                <Form.Item
                  label=""
                  name={[name, "name"]}
                  rules={formRules.modules.name}
                  {...restField}
                >
                  <Input placeholder="type here" className="input-style-1" />
                </Form.Item>
                <Form.Item
                  label=""
                  name={[name, "description"]}
                  rules={formRules.modules.description}
                  {...restField}
                >
                  <Input.TextArea
                    placeholder="type here"
                    className="input-style-1"
                    style={{ minHeight: 150 }}
                  />
                </Form.Item>
                <Row>
                  <Col offset={1} xs={23}>
                    <Typography.Title level={5} className="primary-text-color">
                      Lessons
                    </Typography.Title>
                    <Form.List
                      name={[name, "lessons"]}
                      rules={formRules.modules.lessons.lesson}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map(
                            ({ key, name, ...restField }, lessonIndex) => (
                              <div className="d-flex gap-10" key={key}>
                                <Typography.Paragraph className="mb-0 mt-2 font-semibold primary-text-color">
                                  {lessonIndex + 1}-
                                </Typography.Paragraph>

                                <div className="flex-1">
                                  <Form.Item
                                    label=""
                                    rules={formRules.modules.lessons.name}
                                    name={[name, "name"]}
                                    {...restField}
                                  >
                                    <Input
                                      placeholder="Lesson Name"
                                      className="input-style-1"
                                    />
                                  </Form.Item>
                                  <div className="d-flex justify-content-between">
                                    <Form.Item
                                      valuePropName="lessonFile"
                                      rules={
                                        formRules.modules.lessons.lessonFile
                                      }
                                      name={[name, "lessonFile"]}
                                      {...restField}
                                    >
                                      <Upload
                                        multiple={true}
                                        beforeUpload={() => false}
                                        accept="video/*"
                                      >
                                        <UploadPlaceholder />
                                      </Upload>
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          <AddAnotherButton
                            onClick={() => add()}
                            title="Add Another Lesson"
                          />
                          {errors.length > 0 && (
                            <Form.Item className="m-0">
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Col>
            ))}
            <AddAnotherButton
              onClick={() => add()}
              title="Add Another Module"
            />
            <Form.ErrorList errors={errors} />
          </Row>
        )}
      </Form.List>
    </Form>
  );
};

const UploadPlaceholder = () => (
  <div className="upload-placeholder">
    <img alt="" src={folder} />
    <Typography.Text className="text-small primary-text-color">
      Upload Files
    </Typography.Text>
  </div>
);

const AddAnotherButton = ({ title = "", onClick }) => (
  <div className="add-another-wrapper" onClick={onClick}>
    <button type="button">
      <PlusOutlined />
    </button>
    <Typography.Text className="text-small font-bold">{title}</Typography.Text>
  </div>
);

export default forwardRef(AddCourseContent);
