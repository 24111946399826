import { Col, Row } from "antd";
import { PlaceholderImage } from "assets";
import { CustomCard } from "globalComponents";
import { useNavigate } from "react-router-dom";
import { getServerUrlAttached } from "utils";

export const MentorshipList = ({ instructors, handleInstructorDelete }) => {
  const navigate = useNavigate();

  const column = (instructor, index) => (
    <Col key={index} xs={12} sm={8} md={8} lg={8} xl={6} xxl={6}>
      <CustomCard
        primaryText={instructor?.name}
        primaryTickColor={instructor?.verification}
        secondaryText={instructor?.description}
        imgSrc={instructor?.photo}
        arrowText={"Learn More"}
        item={instructor}
        onDelete={handleInstructorDelete}
        onClick={() =>
          navigate(`/mentorship/detail/${instructor?.instructor_id}`)
        }
      />
    </Col>
  );

  return (
    <div className={"cards-list-container"}>
      <Row className="w-100" gutter={[10, 20]}>
        {instructors.map(column)}
      </Row>
    </div>
  );
};
