import {
  Badge,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import useCourseInformation from "./useCourseInformation";
import dayjs from "dayjs";
import { EyeOutlined } from "@ant-design/icons";
import { getServerUrlAttached } from "utils";

export const CourseInformation = ({ courseDetail, setSelectedMediaFile }) => {
  const { modules } = useCourseInformation({
    courseDetail,
    setSelectedMediaFile,
  });

  return (
    <div className={"course-information-container"}>
      <Row gutter={24}>
        <Col xs={24} md={10} lg={24} xl={10} xxl={10}>
          <Typography.Title level={4} className={"cif-info-title"}>
            {"Course Description"}
          </Typography.Title>
          <Typography.Paragraph level={4} className={"cif-info-detail"}>
            {courseDetail?.course?.description ?? ''}
          </Typography.Paragraph>
        </Col>
        <Col xs={24} md={14} lg={24} xl={14} xxl={14}>
          <Typography.Title level={4} className={"cif-info-title"}>
            {"Lessons List"}
          </Typography.Title>
          <Tabs defaultActiveKey="0" items={modules} />
        </Col>
      </Row>
    </div>
  );
};

export const LessonsList = ({ lessons, setSelectedMediaFile }) => {
    const lesson = (val, index) => (
    <div key={index} className={"lesson-item"}>
      <Typography.Paragraph onClick={() =>
          setSelectedMediaFile(val?.files[0]?.url)} className={"lesson-name-text -mb-3-imp"}>
        {index + 1}
        {". "}
        {val?.lesson.name}
      </Typography.Paragraph>
        <Typography.Paragraph
            className={"lesson-duration-text cursor-pointer  -mb-3-imp"}
        >
            Click to play
        </Typography.Paragraph>
    </div>
  );

  return (
    <div className={"lessons-list-wrapper"}>
      <div className={"lessons-upper-overlay"} />
      <div className={"lessons-lower-overlay"} />
      <div className={"lessons-list-container"}>{lessons.map(lesson)}</div>
    </div>
  );
};

const FileList = ({ setSelectedMediaFile, files }) => (
  <div className="lesson-file-content">
    <Space>
      <Typography.Title className="m-0" level={5}>
        Files
      </Typography.Title>
      <Badge count={files?.length} color="#faad14" />
    </Space>
    <Divider className="lesson-file-divider" />
    <Space direction="vertical">
      {files.map((file, index) => (
        <div className="lesson-file" key={index}>
          <Typography.Title level={5}>
            {dayjs(file?.created_at).format("DD/MM/YYYY hh:mm:ss")}
          </Typography.Title>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            size="small"
            onClick={() =>
              setSelectedMediaFile(file?.url)
            }
          ></Button>
        </div>
      ))}
    </Space>
  </div>
);
