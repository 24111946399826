import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Button, Calendar, Typography } from "antd";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import useAppointment from "./useAppointment";
import {useEffect, useState} from "react";
// import weekday from "dayjs/plugin/weekday";
// import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

const Appointment = () => {
  const {
    // handleDateChange,
  } = useAppointment();

  const [selectedDate, setSelectedDate] = useState();
  const [schedules, setSchedules] = useState();
  const [isSlotSelected, setIsSlotSelected] = useState(false);
  const [isBookButtonDisabled, setIsBookButtonDisabled] = useState(false);
  const [messageContext, setMessageContext] = useState();
  const [loading, setLoading] = useState(false);

    const [selectedSlots, setSelectedSlots] = useState([]);

    const handleDateChange = (current) => {
        setSelectedDate(current);
    };
    const disabledDate = (current) => current.isBefore(dayjs(), "day");

    const handleSlotSelect = (val) => setSelectedSlots((curr) => {
        const currCopy = [...curr];
        const foundedIndex = currCopy.findIndex((currItem) => currItem === val);
        if (foundedIndex > -1) {
            currCopy.splice(foundedIndex, 1);
        } else {
            currCopy.push(val);
        }
        return currCopy;
    });

    const handleBooking = async () => {
        // const data = {
        //     start: selectedSlots,
        //     instructors_id: instructorId,
        // };
        // const result = await callBookSlotApi({
        //     data,
        // });
        //
        // if (result?.success) {
        //     setSelectedSlots([]);
        //     getInstructorSchedule();
        // }
    };

  function initializePage() {

  };

  useEffect(() => {

  },[]);

  return (
    <>
      {messageContext}
      <div className={"appointment-container"}>
        <div gutter={24} className={"a-calendar-wrapper"}>
          <div className={"calendar-container"}>
            <Calendar
              defaultValue={selectedDate}
              className={"calendar"}
              fullscreen={false}
              disabledDate={disabledDate}
              onSelect={handleDateChange}
              headerRender={({ value, onChange }) => (
                <CalendarHeader value={value} onChange={onChange} />
              )}
            />
          </div>
          <AppointmentTimes
            handleSlotSelect={handleSlotSelect}
            schedules={schedules}
            selectedDate={selectedDate}
            isSlotSelected={isSlotSelected}
            isBookButtonDisabled={isBookButtonDisabled}
            handleBooking={handleBooking}
            bookSlotApiLoading={loading}
          />
        </div>
      </div>
    </>
  );
};

const CalendarHeader = ({ value, onChange }) => {
  const month = new Date(value).toLocaleString("default", { month: "long" });
  const year = new Date(value).getFullYear();
  return (
    <div className={"calendar-header-container"}>
      <Typography.Paragraph className={"calendar-header-text"}>
        {"Select a Date & Time"}
      </Typography.Paragraph>
      <div className={"calendar-month-year-container"}>
        <Typography.Paragraph
          className={"month-year-text"}
        >{`${month} ${year}`}</Typography.Paragraph>
        <div className={"calendar-arrows-container"}>
          {!value.isSame(dayjs(), "M") && (
            <LeftOutlined
              onClick={() =>
                onChange(value.clone().month(new Date(value).getMonth() - 1))
              }
            />
          )}
          <RightOutlined
            onClick={() =>
              onChange(value.clone().month(new Date(value).getMonth() + 1))
            }
          />
        </div>
      </div>
    </div>
  );
};

const AppointmentTimes = ({
  schedules,
  selectedDate,
  handleSlotSelect,
  isSlotSelected,
  isBookButtonDisabled,
  handleBooking,
  bookSlotApiLoading,
}) => {
  const appointment = (val, index) => (
    <div
      key={index}
      onClick={() => handleSlotSelect(val)}
      className={`outlined-div ${isSlotSelected(val) ? "is-selected" : ""}`}
    >
      {dayjs(val).format("hh:mm:ss A")}
    </div>
  );
  return (
    <div className={"appointment-time-container"}>
      <Typography.Paragraph className={"at-month-day-text"}>
        {selectedDate ? selectedDate.format("dddd, MMMM D") : ''}
      </Typography.Paragraph>
      <div className={"at-times-wrapper"}>
        {(schedules ?? [])?.length === 0 ? (
          <Alert message="No Slots Available" type="warning" showIcon />
        ) : (
          schedules.map(appointment)
        )}
      </div>
      <Button
        size="large"
        disabled={isBookButtonDisabled}
        type="primary"
        block
        style={{ marginTop: 20 }}
        onClick={handleBooking}
        loading={bookSlotApiLoading}
      >
        Book
      </Button>
    </div>
  );
};

export default Appointment;
