import { Button, Form, Typography, Upload } from "antd";
import { thumbnail } from "assets";
import { useEffect, useState } from "react";
import { getImageUrlByFile } from "utils";
import "./styles.scss";
import { useWatch } from "antd/es/form/Form";
const ImagePicker = ({
  title = "",
  description = "",
  name,
  rules = [],
  form,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const imageField = useWatch(name, form);

  useEffect(() => {
    if (imageField) {
      const file = imageField?.fileList?.[0]?.originFileObj;
      if (file) {
        setImageUrl(getImageUrlByFile(file));
      }
    } else {
      setImageUrl("");
    }
  }, [imageField]);

  const handleFileRemove = () => {
    form.resetFields([name]);
  };

  return (
    <Form.Item
      className="image-picker-upload-form-item"
      name={name}
      valuePropName={name}
      rules={rules}
    >
      <Upload
        className="image-picker-upload"
        multiple={false}
        disabled={imageUrl}
        accept="image/*"
        maxCount={1}
        beforeUpload={() => false}
        listType={null}
      >
        <div className="image-picker">
          <img
            src={imageUrl || thumbnail}
            alt=""
            className={imageUrl ? "with-shadow" : ""}
          />
          <div className="ip-text-wrapper">
            {imageUrl ? (
              <Button
                onClick={handleFileRemove}
                className="text-small"
                type="primary"
              >
                Remove
              </Button>
            ) : (
              <>
                <Typography.Title
                  className="cursor-pointer font-bold m-0"
                  level={4}
                >
                  {title}
                </Typography.Title>
                <Typography.Text className="text-small">
                  {description}
                </Typography.Text>
              </>
            )}
          </div>
        </div>
      </Upload>
    </Form.Item>
  );
};

export default ImagePicker;
