export const dummyChat = [
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Verified users’ message will look different. Message text goes here. Message text goes here.',
    from: 'Ethan Duran',
    premium: true,
  },

  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Hi i am the admin',
    from: 'your username',
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },

  {
    text: 'Hi i am the admin',
    from: 'your username',
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Verified users’ message will look different. Message text goes here. Message text goes here.',
    from: 'Ethan Duran',
    premium: true,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Message text goes here. Message text goes here. Message text goes here. Message text goes here.',
    from: 'Name',
    premium: false,
  },
  {
    text: 'Hi i am the admin',
    from: 'your username',
  },
]
