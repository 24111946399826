export const formRules = {
  name: [
    {
      required: true,
      message: "Name is required",
    },
  ],
  description: [
    {
      required: true,
      message: "Description is required",
    },
  ],
  fromPrice: [
    {
      required: true,
      message: "Please add starting price",
    },
  ],
  toPrice: [
    {
      required: true,
      message: "Please add ending Price",
    },
  ],
  thumbnail: [{ required: true, message: "Thumbnail is required" }],
};
