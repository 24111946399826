import { Col, Row, Typography } from "antd";
import { projectCard } from "assets";

const MySubscription = () => {
  return (
    <div className="subscription-wrapper">
      <Row gutter={[10, 10]}>
        <Col xs={12} md={24} lg={12}>
          <SubscriptionCard />
        </Col>

        <Col xs={12} md={24} lg={12}>
          <SubscriptionCard />
        </Col>
      </Row>
    </div>
  );
};

const SubscriptionCard = () => (
  <div className="subscription-card">
    <img src={projectCard} alt="" />
    <div className="right">
      <Typography.Text className="text-small text-underline font-bold primary-text-color">
        Courses
      </Typography.Text>
      <Typography.Text className="font-bold">Funeral</Typography.Text>
      <Typography.Text className="text-small">Ethan Duran</Typography.Text>
    </div>
  </div>
);

export default MySubscription;
