import { Col, Row, Typography } from 'antd'
import { mapCards } from '../config/constants'
import {useNavigate} from "react-router-dom";

export const MapCardContainer = () => {

  const navigagte = useNavigate();

  const onCardClick = (text) => {
    if (text === 'Enroll in classes') {
      console.log('Classes')
      navigagte('/courses')
    } else {
      alert(text)
    }
  }

  const card = (val, index) => (
    <Col key={index} xs={24} lg={8} xl={8} xxl={8} className={'card-container'}>
      <div className={'card-wrapper'} onClick={() => onCardClick(val.text)}>
        <img className={'card-image'} src={val.image} alt='card' />
        <div className={'card-text'}>
          <Typography.Title className='c-text' level={4}>
            {val.text}
          </Typography.Title>
          <div className={'card-underline'} />
        </div>
      </div>
    </Col>
  )

  return (
    <Row gutter={20} className={'mcc-card-container'}>
      {mapCards.map(card)}
    </Row>
  )
}
