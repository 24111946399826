export const formRules = {
  email: [
    {
      required: true,
      message: "Email is required",
    },
  ],
  usernmae: [
    {
      required: true,
      message: "Username is required",
    },
  ],
  password: [
    {
      required: true,
      message: "Password is required",
    },
  ],
  description: [
    {
      required: true,
      message: "Description is required",
    },
  ],
  verification: [
    {
      required: true,
      message: "Please select",
    },
  ],
  picture: [
    {
      required: false,
      message: "Picture is required",
    },
  ],
};
