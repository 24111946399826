import {Outlet, useNavigate} from "react-router-dom";
import { EducatorsList } from "./components/EducatorsList";
import "./styles.scss";
import useEducator from "./useEducator";
import userProvider from "../../../context/user/userProvider";
import NetworkingAPI from "../../../context/NetworkingAPI";
import {useEffect, useState} from "react";

const EducatorPage = () => {

  const [selectedInstructorId, setSelectedInstructorId] = useState();
  const [instructors, setInstructors] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  function handleEducatorClick(item) {
    navigate(`/educator/detail/${item?.instructor_id}`);
  }

  async function initializePage() {
    const currentUser = await userProvider.getCurrentUser();

    setLoading(true)
    if (!currentUser) {
      return console.log("Please enter a valid email address");
    }
    try {
      const response = await NetworkingAPI.fetchData('course/get-educators', 'GET', undefined);
      const data = response.data;
      setInstructors(data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    initializePage();
  },[]);

  return (
    <div className={"educator-page-container"}>
      {
        instructors && (
            <>
              <EducatorsList
                  onSelect={handleEducatorClick}
                  instructors={instructors}
                  selectedInstructorId={selectedInstructorId}
                  getInstructorsApiLoading={loading}
              />
            </>
          )
      }
      <div className={"educator-appointment-wrapper"}>
        <Outlet />
      </div>
    </div>
  );
};

export default EducatorPage;
