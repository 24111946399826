import { Col, Row, Typography } from 'antd'
import { affiliateThumbnail } from 'assets'
import { PrimaryButton } from 'globalComponents'

export const AffiliateGetStarted = ({ updateUserStatus }) => {
  return (
    <div className={'ags-start-container'}>
      <Row>
        <Col lg={12}>
          <div className={'ags-title'}>
            <Typography.Title level={1} className={'title'}>
              {'Become a Corsa University Affiliate'}
            </Typography.Title>
            <Typography.Paragraph style={{ color: 'black'}} className={'paragraph'}>
              {
                'Why? You will help your friends break free from the rat mentality. You will get an incredible discount on your current subscription. Your account will be verified as a premium member.'
              }
            </Typography.Paragraph>
            <PrimaryButton text={'Get Started'} onClick={()=>updateUserStatus(false)} style={{ width: 160, marginBottom: 40, backgroundColor: '#57C1EB' }} />
          </div>
        </Col>
        <Col lg={12}>
          <div className={'ags-thumbnail'}>
            <img src={affiliateThumbnail} alt={'thumbnail'} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={'ags-second-container'} sm={22} md={12} style={{ marginTop: 60 }}>
          <div className={'ags-title'}>
            <Typography.Title level={1} className={'title small'}>
              {'The Tiers'}
            </Typography.Title>
            <Typography.Paragraph style={{ color: 'black'}} className={'paragraph smaller'}>
              {
                'You get more $$ to straight to your bank account as you refer more friends. Once you hit the Platinum milestone, you will be certified a Platinum user with a badge added next to your name.'
              }
            </Typography.Paragraph>
          </div>
        </Col>
      </Row>
    </div>
  )
}
