import { Col, Row } from 'antd'
import { dashboardMap } from 'assets'
import { MapDetailItem } from './DetailItem'

export const MapContainer = () => {
  return (
    <Row className={'mc-map-details-container'}>
      <Col xs={24} lg={18} xl={14} xxl={18} className={'mc-map-container'}>
        <img className={'mc-map'} src={dashboardMap} alt='map' />
      </Col>
      <Col  xs={24} lg={4} xl={10} xxl={6} className={'mc-details-container'}>
        <MapDetailItem />
        <MapDetailItem />
        <MapDetailItem />
        <MapDetailItem />
        <MapDetailItem />
      </Col>
    </Row>
  )
}
