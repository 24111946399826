import { PlusOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import React from 'react'

const queries = [
  {
    title: 'How Can I Book an Call?',
    desc: 'Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. ',
  },
  {
    title: 'How Can I Book an Call?',
    desc: 'Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. ',
  },
  {
    title: 'How Can I Book an Call?',
    desc: 'Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. ',
  },
  {
    title: 'How Can I Book an Call?',
    desc: 'Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. Some answer to the question. ',
  },
]

export const FAQList = () => {
  const { Panel } = Collapse

  const panel = ({ title, desc }, index) => (
    <Panel header={title} key={index}>
      <p style={{ maxWidth: '90%', textAlign: 'justify' }}>{desc}</p>
    </Panel>
  )

  return (
    <div className={'faq-list-container'}>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition={'end'}
        expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 45 : 0} />}
      >
        {queries.map(panel)}
      </Collapse>
    </div>
  )
}
