export const rightArrowSvg = (
  <svg width='17.109' height='27.715' viewBox='0 0 17.109 27.715'>
    <g id='Group_9536' data-name='Group 9536' transform='translate(3.054 0.482) rotate(45)'>
      <line
        id='Line_3'
        data-name='Line 3'
        x2='19.196'
        transform='translate(0 1.819)'
        fill='none'
        stroke='#fff'
        strokeWidth='5'
      />
      <line
        id='Line_4'
        data-name='Line 4'
        x2='18.844'
        transform='translate(17.17 0) rotate(90)'
        fill='none'
        stroke='#fff'
        strokeWidth='5'
      />
    </g>
  </svg>
)
