import { CustomModal, StepsProgress } from "globalComponents";
import useManageCourseModal from "./useManageCourseModal";
import "./styles.scss";

const ManageCourseModal = ({ open, onCancel }) => {
  const { activeStep, stepsComponent, onNextStep, addCourseApiLoading } =
    useManageCourseModal({
      onCancel,
    });

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      width={[1, 2].includes(activeStep) ? 550 : 800}
      className="course-modal"
      destroyOnClose
    >
      <div className="course-modal-content">{stepsComponent[activeStep]}</div>
      <div className="course-modal-footer">
        <StepsProgress
          total={3}
          activeStep={activeStep}
          onNextStep={onNextStep}
          loading={addCourseApiLoading}
        />
      </div>
    </CustomModal>
  );
};

export default ManageCourseModal;
