import { enrollInClasses } from 'assets'
import { inviteAFriend } from 'assets'
import { joinTheCall } from 'assets'

export const mapCards = [
  {
    text: 'Join the live call',
    image: joinTheCall,
  },
  {
    text: 'Invite a friend',
    image: inviteAFriend,
  },
  {
    text: 'Enroll in classes',
    image: enrollInClasses,
  },
]
