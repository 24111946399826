import { Col, Row, Space, Typography } from "antd";

import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import "./package.scss"

const PackagePage = () => {
    const Navigate =useNavigate()
  return (
    <div className=" package-area">
      <Row>
      <Col className="flex flex-col gutter-row left-content " span={12}>
          <div className="relative flex flex-col items-start justify-between w-full h-full p-10">
              <div className={"left-content-2"}>
                  <div className="relative z-10 w-full top-content">
                      <h1>Select a package</h1>
                      <p>Fill all the required inputs or you won't be able to jump
                          to the next step.</p>
                      <div className="flex items-start package-list ">
                          <div className="w-2/4 p-4">
                              <div className="package-items">
                                  <div className="flex items-center justify-between w-full top-bar">
                                      <div>
                                          {/*<button type="button" className="mb-2 btn">Package 1</button>*/}
                                          <h2>Distributor</h2>
                                      </div>
                                      <div>
                                          <h1 className="price">$12</h1>
                                      </div>
                                  </div>
                                  <div className="divider"></div>
                                  <ul className="nav">
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details">
                                              All features in <strong>Personal.</strong>
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details">
                                              Unclock <strong>Teams</strong> to create a work group.
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details"><strong>20GB </strong> of shared space.
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details"><strong>20GB </strong> of shared space.
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details"><strong>20GB </strong> of shared space.
                                          </p>
                                      </li>
                                  </ul>
                                  <button type="button" className="package-btn">Select Package 1</button>
                              </div>
                          </div>
                          <div className="w-2/4 p-4">
                              <div className="package-items">
                                  <div className="flex items-center justify-between w-full top-bar">
                                      <div>
                                          {/*<button type="button" className="mb-2 btn">Package 2</button>*/}
                                          <h2>Affiliate</h2>
                                      </div>
                                      <div>
                                          <h1 className="price">$24</h1>
                                      </div>
                                  </div>
                                  <div className="divider"></div>
                                  <ul className="nav">
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details">
                                              All features in <strong>Personal.</strong>
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details">
                                              Unclock <strong>Teams</strong> to create a work group.
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details"><strong>20GB </strong> of shared space.
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details"><strong>20GB </strong> of shared space.
                                          </p>
                                      </li>
                                      <li className="flex items-center nav-item">
                                          <div className="icon">
                                              <FaCheck/>
                                          </div>
                                          <p className="details"><strong>20GB </strong> of shared space.
                                          </p>
                                      </li>
                                  </ul>
                                  <button type="button" className="package-btn">Select Package 2</button>
                              </div>
                          </div>
                      </div>
                      <button type="button" className="mt-5 go-next" onClick={() => Navigate("/auth/checkout")}>
                          Continue
                      </button>
                  </div>
              </div>
              <div className="relative z-10 w-full bottom-content">
                  <div className="flex items-center divider-list">
                      <div style={{marginBottom: '20px'}} className="divider"></div>
                  </div>
                  <div className="signup-text">
                      <NavLink to="/auth/login">
                          Already have an account? <strong>Sign in</strong>
                      </NavLink>
                  </div>
              </div>
              <div className="absolute bottom-0 left-0 overly-effect ">
                  <img src="/img/login/effect.png" alt="effect"/>
              </div>
          </div>
      </Col>
          <Col
              className="flex flex-col items-center justify-center h-full gutter-row"
              span={12}
          >
              <div className="relative flex items-center justify-center w-full right-content">
                  <img
                      src="/img/package.png"
                      alt="effect2"
                      className="effect"
                  />
                  <div
                      className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full overly-box">
                      <img src="/img/login/logo.png" alt=""/>
                      <h2>Corsa University</h2>
                  </div>
              </div>
          </Col>
      </Row>
    </div>
  );
};

export default PackagePage;
