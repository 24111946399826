import { ConfigProvider, theme } from "antd";
import variables from "styles/antd/variables.json";

const WithTheme = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: variables,
        algorithm: theme.darkAlgorithm,
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default WithTheme;
