const userProvider = {

    getCurrentUser(shouldRefetch) {
        const userObject = localStorage.getItem('user');
        if (shouldRefetch) {
            console.log('Refetching...')
        } else {
            if (userObject) {
                return JSON.parse(userObject)
            } else {
                return undefined
            }
        }
    },

    signOutUser() {
        localStorage.removeItem('user')
    }
}

export default userProvider