import { Typography } from 'antd'
import { copyImage } from 'assets'
import React from 'react'

export const AffiliateCopy = () => {
  return (
    <div className={'ac-copy-container'}>
      <img className={'ac-copy-img'} src={copyImage} alt={'copy'} />
      <Typography.Paragraph className={'ac-link'}>
        <span className={'highlighted'}>{'Your Link: '}</span>
        <span className='link'>{'https://www.corsauniversity.com/ref/usef12'}</span>
      </Typography.Paragraph>
    </div>
  )
}
