import { useMemo } from "react";
import { LessonsList } from ".";

const useCourseInformation = ({ courseDetail, setSelectedMediaFile }) => {
  const modules = useMemo(
    () =>
      Array.isArray(courseDetail?.modules)
        ? (courseDetail?.modules ?? []).map((module, index) => ({
            key: `${index}`,
            label: module.module.name,
            children: (
              <LessonsList
                lessons={module?.lessons}
                setSelectedMediaFile={setSelectedMediaFile}
              />
            ),
          }))
        : [],
    [courseDetail?.modules, setSelectedMediaFile]
  );

  return { modules };
};

export default useCourseInformation;
