import React, {useEffect} from "react";
import "./styles.scss";
import { Button, Typography } from "antd";
import { CoursesList } from "./components/CoursesList";
import useCourses from "./useCourses";
import ManageCourseModal from "./components/ManageCourseModal";
import { Loader } from "globalComponents";
import NetworkingAPI from "../../../context/NetworkingAPI";
import userProvider from "../../../context/user/userProvider";

const CoursesPage = () => {
  // const {
  //   isManageCourseModalVisible,
  //   toggleManageCourseModalVisibility,
  //   loading,
  //   courses,
  //   instructorName,
  // } = useCourses();

  const [isManageCourseModalVisible, setIsManageCourseModalVisible] = React.useState(false);
  // const [courses, setCourses] = React.useState();
  const [instructorName, setInstructorName] = React.useState("");
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function toggleManageCourseModalVisibility() {
    setLoading(!loading)
  }

  async function initializePage() {
    const currentUser = await userProvider.getCurrentUser();

    if (!currentUser) {
      return console.log("Please enter a valid email address");
    }
    try {
      const response = await NetworkingAPI.fetchData('course/get-courses', 'GET', undefined);
      const data = response.data;
      setCourses(data.data)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    initializePage()
  },[]);

  return (
    <>
      <ManageCourseModal
        open={isManageCourseModalVisible}
        onCancel={toggleManageCourseModalVisibility}
      />
      {loading && <Loader />}
      <div className={"courses-page-container cards-main-container"}>
        <div className={"cards-title"}>
          <Typography.Title className={"title"} level={4}>
            {`${instructorName ? instructorName : "Our"} Courses`}
          </Typography.Title>
          <Button style={{backgroundColor: 'darkgrey'}} type="primary" onClick={toggleManageCourseModalVisibility}>
            Add
          </Button>
        </div>
        <CoursesList courses={courses} />
      </div>
    </>
  );
};

export default CoursesPage;
