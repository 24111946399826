import { Col, Row, Space, Typography } from "antd";
import { PrimaryButton } from "globalComponents";
import { CustomInput } from "globalComponents";
import { getFormRules } from "./formRules";
import useLogin from "./useLogin";
import './login.scss'
import { FaEye,FaEyeSlash} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import NetworkingAPI from "../../../context/NetworkingAPI";
const LoginPage = () => {

  const { handleLoginFormSubmit, register, errors, loading } = useLogin();
  const [showPassword,setShowPass] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [loading, setLoading] = useState(false);

  async function handleUserLogin() {
      if (email === '' || password === '') {
          return console.log("Please enter a valid email address");
      }
      try {
          const response = await NetworkingAPI.fetchData('authentication/login', 'POST', {email: email, password: password});
          if (response.error) {
              return console.log(response.error)
          }
          const data = response.data;
          // console.log(data.data)
          localStorage.setItem('user', JSON.stringify(data.data));
          window.location.reload();
      } catch (e) {
          console.log(e);
      }
  }

  return (
    <div className="h-100 auth-form-wrapper login-area">
      <Row >
      <Col className="flex flex-col gutter-row left-content " span={12}>
        <div className="relative flex flex-col items-start justify-between w-full h-full p-10">
            <div className={"left-content-2"}>
                <div className="relative z-10 w-full top-content">
                    <h1>Sign in to Your account</h1>
                    <p> Enter your email address and password to log in.</p>

                    <form onSubmit={handleLoginFormSubmit} className="pt-10">
                        <div className="mb-3 w-full ">
                            <div className="relative">
                                <CustomInput placeholder={'johndoe@gmail.com'} label={'Email Address'} onChange={e=> setEmail(e.target.value)} />
                                <div className="absolute flex items-center h-full top-3 left-3 icon">
                                    <img src="/img/sms.svg" alt="sms" className=""/>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 w-full">
                            <div className="relative">
                                <CustomInput type={'password'} label={'Password'} onChange={e=> setPassword(e.target.value)} showPassword={showPassword}/>
                                <div className="absolute top-3 flex items-center h-full left-3 icon">
                                    <img src="/img/lock.svg" alt="sms"/>
                                </div>
                                <div className="absolute flex items-center h-full top-3 right-4 icon">
                                    <button type="btn" className="btn" onClick={() => setShowPass(!showPassword)}>
                                        {
                                            showPassword ? <FaEyeSlash/> : <FaEye/>
                                        }
                                    </button>
                                </div>

                            </div>
                            <div className="text-end forget-password">
                                <NavLink to="">Forgot password?</NavLink>
                            </div>
                        </div>

                        <PrimaryButton
                            htmlType="submit"
                            loading={loading}
                            text="Login"
                            onClick={() => handleUserLogin()}
                            className="auth-btn"
                        />
                    </form>
                </div>
            </div>
            <div className="relative z-10 w-full bottom-content">
                <div className="flex items-center divider-list">
                    <div style={{marginBottom: '20px'}}className="divider"></div>
                </div>
                <div className="signup-text">
                    <NavLink to="/auth/register">Don't you have an account? <strong> Sign Up</strong></NavLink>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 overly-effect ">
                <img src="/img/login/effect.png" alt="effect"/>
            </div>
        </div>
      </Col>
          <Col className="flex flex-col items-center justify-center h-full gutter-row" span={12}>
              <div className="relative flex items-center justify-center w-full right-content">
                  <img src="/img/login/effect2.png" alt="effect2" className="effect"/>
                  <div
                      className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full overly-box">
                      <img src="/img/login/logo.png" alt=""/>
                      <h2>Corsa University</h2>
                  </div>
              </div>
          </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
