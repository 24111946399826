import { Col, Row  } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";

import './checkout.scss'
import { useState } from "react";
const CheckOutPage = () => {
    const Navigate =useNavigate()
    const [selectPayment,setSelect] = useState('card')
    const [check,setCheck] = useState(false)
  return (
    <div className="checkout-area">
      <Row>
        <Col className="flex flex-col gutter-row left-content " span={12}>
          <div className="relative flex flex-col items-start justify-between w-full h-full p-10">
              <div className={"left-content-2"}>
                  <div className="relative z-10 w-full top-content">
                      <h1>Select a payment method</h1>
                      <p>Choose a Payment method </p>
                      <div className="p-5 mt-4 payment-method">
                          <div className="top-bar">
                              <div className="flex items-center justify-between">
                                  <div className="w-4/12 p-3">
                                      <div
                                          className={`relative flex items-center justify-center w-full p-2 text-center card-item ${selectPayment === 'card' ? 'active' : ''}`}>
                                          <button className="flex flex-col items-center justify-center text-center btn"
                                                  type="button" onClick={() => setSelect('card')}>
                                              <img src="/img/card.png" alt="card"/>
                                              <p>Credit Card</p>
                                          </button>
                                          <div
                                              className={`absolute left-3 top-2 status  ${selectPayment === 'card' ? 'flex' : 'hidden'}`}>
                                              <FaCheck/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="w-4/12 p-3">
                                      <div
                                          className={`relative flex items-center justify-center w-full p-2 text-center card-item ${selectPayment === 'paypal' ? 'active' : ''}`}>
                                          <button className="flex flex-col items-center justify-center text-center btn"
                                                  type="button" onClick={() => setSelect('paypal')}>
                                              <img src="/img/paypal.png" alt="card"/>
                                              <p>Paypal</p>
                                          </button>
                                          <div
                                              className={`absolute left-3 top-2 status  ${selectPayment === 'paypal' ? 'flex' : 'hidden'}`}>
                                              <FaCheck/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="w-4/12 p-3">
                                      <div
                                          className={`relative flex items-center justify-center w-full p-2 text-center card-item ${selectPayment === 'dollar' ? 'active' : ''}`}>
                                          <button className="flex flex-col items-center justify-center text-center btn"
                                                  type="button" onClick={() => setSelect('dollar')}>
                                              <img src="/img/dollar.png" alt="card"/>
                                              <p>Paycash</p>
                                          </button>
                                          <div
                                              className={`absolute left-3 top-2 status  ${selectPayment === 'dollar' ? 'flex' : 'hidden'}`}>
                                              <FaCheck/>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <form>
                              <div className="flex flex-wrap items-center p-5">
                                  <div className="mb-3 w-6/12 pr-2">
                                      <input
                                          type="text"
                                          className="form-control w-full"
                                          name=""
                                          id=""
                                          aria-describedby="helpId"
                                          placeholder="CARD NUMBER"
                                      />

                                  </div>
                                  <div className="mb-3 w-6/12 pl-2">
                                      <input
                                          type="text"
                                          className="form-control w-full"
                                          name=""
                                          id=""
                                          aria-describedby="helpId"
                                          placeholder="EXPIRATION DATE"
                                      />

                                  </div>
                                  <div className="mb-3 w-6/12 pr-2">
                                      <input
                                          type="text"
                                          className="form-control w-full"
                                          name=""
                                          id=""
                                          aria-describedby="helpId"
                                          placeholder="CARD HOLDER NAME"
                                      />

                                  </div>
                                  <div className="mb-3 w-6/12 pl-2">
                                      <input
                                          type="text"
                                          className="form-control w-full"
                                          name=""
                                          id=""
                                          aria-describedby="helpId"
                                          placeholder="CVV"
                                      />

                                  </div>
                                  <div className="flex items-center w-full save-payment">
                                      <div className="mr-2 check-button">
                                          <button type="button" className={`"btn" ${check ? 'active-check' : ''}`}
                                                  onClick={() => setCheck(!check)}><FaCheck/></button>
                                      </div>
                                      <p>Save my Payment method info for future Update</p>
                                  </div>

                                  <button type="button"
                                          className="flex items-center justify-between w-full px-4 price-box">
                                      <p className="select-package">Select package : <strong>Distributor</strong></p>
                                      <p className="price">Price : <strong> $12</strong></p>
                                  </button>
                              </div>
                          </form>
                      </div>
                      <button type="button" className="mt-5 go-next" onClick={() => Navigate("/auth/verify")}>
                          Continue
                      </button>
                  </div>
              </div>
              <div className="relative z-10 w-full bottom-content">
                  <div className="flex items-center divider-list">
                      <div style={{marginBottom: '20px'}} className="divider"></div>
                  </div>
                  <div className="signup-text">
                      <NavLink to="/auth/login">
                          Already have an account? <strong>Sign in</strong>
                      </NavLink>
                  </div>
              </div>
              <div className="absolute bottom-0 left-0 overly-effect ">
                  <img src="/img/login/effect.png" alt="effect"/>
              </div>
          </div>
        </Col>
          <Col
              className="flex flex-col items-center justify-center h-full gutter-row"
              span={12}
          >
              <div className="relative flex items-center justify-center w-full right-content">
                  <img
                      src="/img/checkout.png"
                      alt="effect2"
                      className="effect"
                  />
                  <div
                      className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full overly-box">
                      <img src="/img/login/logo.png" alt=""/>
                      <h2>Corsa University</h2>
                  </div>
              </div>
          </Col>
      </Row>
    </div>
  );
};

export default CheckOutPage;
