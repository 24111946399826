import { getAdminDashboardAxiosParams } from "api/useAxiosParams";
import { useAxios } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const useAdmin = () => {
  const {
    loading: adminDashboardApiLoading,
    result: adminDashboardApiResult,
    callAxios: callGetAdminDashboardApi,
  } = useAxios(getAdminDashboardAxiosParams);

  const [isManageMentorModalVisible, setisManageMentorModalVisible] =
    useState(false);

  const [isManageCourseModalVisible, setIsManageCourseModalVisible] =
    useState(false);

  const navigate = useNavigate();

  const toggleManageModalVisibility = useCallback(
    (cb, value) =>
      async ({ success }) => {
        cb(!value);
        if (success) {
          await callGetAdminDashboardApi();
        }
      },
    [callGetAdminDashboardApi]
  );

  const educatorAddNewTitleBtnProps = useMemo(
    () => ({
      title: "Add New Educator",
      onClick: toggleManageModalVisibility(
        setisManageMentorModalVisible,
        isManageMentorModalVisible
      ),
    }),
    [isManageMentorModalVisible, toggleManageModalVisibility]
  );
  const coursesAddNewTitleBtnProps = useMemo(
    () => ({
      title: "Add New Course",
      onClick: toggleManageModalVisibility(
        setIsManageCourseModalVisible,
        isManageCourseModalVisible
      ),
    }),
    [isManageCourseModalVisible, toggleManageModalVisibility]
  );
  const mentorshipAddNewTitleBtnProps = useMemo(
    () => ({
      title: "Add New Mentorship",
      onClick: toggleManageModalVisibility(
        setisManageMentorModalVisible,
        isManageMentorModalVisible
      ),
    }),
    [isManageMentorModalVisible, toggleManageModalVisibility]
  );
  const handleViewClick = (href) => href && navigate(href);

  const listings = useMemo(
    () => ({
      mentors: adminDashboardApiResult?.data?.mentors.map((item) => ({
        label: item?.username,
        href: `/mentorship/detail/${item.id}`,
        ...item,
      })),
      instructors: adminDashboardApiResult?.data?.instructors.map((item) => ({
        label: item?.username,
        href: `/educator/detail/${item.instructors?.id}`,
        ...item,
      })),
      courses: adminDashboardApiResult?.data?.courses.map((item) => ({
        label: item?.name,
        href: `/courses/detail/${item.id}`,
        ...item,
      })),
    }),
    [adminDashboardApiResult]
  );

  return {
    educatorAddNewTitleBtnProps,
    coursesAddNewTitleBtnProps,
    mentorshipAddNewTitleBtnProps,
    adminDashboardApiLoading,
    listings,
    handleViewClick,
    toggleManageModalVisibility,
    isManageCourseModalVisible,
    setIsManageCourseModalVisible,
    setisManageMentorModalVisible,
    isManageMentorModalVisible,
  };
};

export default useAdmin;
