export const downArrowSvg = (
  <svg width='11.221' height='7.025' viewBox='0 0 11.221 7.025'>
    <path
      id='Path_18179'
      data-name='Path 18179'
      d='M1735.3,37.1l4.9,4.9,4.9-4.9'
      transform='translate(-1734.591 -36.392)'
      fill='none'
      stroke='#000'
      strokeWidth='2'
    />
  </svg>
)
