import axios from "axios";
import { LOCAL_STORAGE_VALUES } from "constants/common";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// useEffect(() => {
//   // axios interceptor to handle 401 response and logout user
//   axiosInstance.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       if (error.response.status === 401) {
//         setIsAuthenticated(false);
//       }
//       return Promise.reject(error);
//     }
//   );
// }, []);

// to add token in request if present
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_VALUES.TOKEN);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
