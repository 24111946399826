import { Typography } from 'antd'

export const SidebarListItem = ({ image, text, path, selected, onClick }) => {
  return (
    <div
      className={`sli-container  ${selected ? 'sli-container-selected' : ''}`}
      onClick={() => onClick(path)}
    >
      <div className={'sli-img-container'}>
        <img className={'sli-img'} src={image} alt={'sidebar icon'} />
      </div>
      <div className={'sli-text-container'}>
        <Typography.Paragraph className={'sli-text -mb-3-imp'}>{text}</Typography.Paragraph>
      </div>
    </div>
  )
}
