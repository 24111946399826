import { Button, Typography } from 'antd'
import { chatLockImage } from 'assets'
import { lockImage } from 'assets'
import React from 'react'

export const ChatHeader = ({ locked }) => {
  return (
    <div className={'chat-header-container'}>
      <Button type={'default'} className={'ch-outlined'}>
        <img src={locked ? chatLockImage : lockImage} alt={'lock'} />
        <Typography.Text className={'ch-btn-text lh-0'}>{'Chat'}</Typography.Text>
      </Button>
      <Typography.Text className={'ch-title-text'}>{'Join the Conversation'}</Typography.Text>
    </div>
  )
}
