import { Col, Row, Typography } from 'antd'
import { PrimaryButton } from 'globalComponents'
import React from 'react'

export const AffiliateDashboardCards = () => {
  return (
    <div className={'adc-card-container'}>
      <Typography.Title className={'adc-card-title'} level={3}>
        {'Your Dashboard'}
      </Typography.Title>
      <Row>
        <Col xs={24} sm={8}>
          <LinkCard />
        </Col>
        <Col xs={24} sm={8}>
          <InvitedCard />
        </Col>
        <Col xs={24} sm={8}>
          <WithdrawCard />
        </Col>
      </Row>
    </div>
  )
}

const LinkCard = () => (
  <div className={'card-container'}>
    <Typography.Title level={1} className={'number'}>
      {'20'}
    </Typography.Title>
    <Typography.Paragraph className={'text'}>{'Link clicks'}</Typography.Paragraph>
  </div>
)

const InvitedCard = () => (
  <div className={'card-container invited-card'}>
    <Typography.Title level={1} className={'number'}>
      {'7'}
    </Typography.Title>
    <Typography.Paragraph className={'text'}>{'Friends Invited'}</Typography.Paragraph>
  </div>
)
const WithdrawCard = () => (
  <div className={'card-container withdraw-card'}>
    <Typography.Title style={{ marginBottom: '10px'}} level={1} className={'number'}>
      {'$100'}
    </Typography.Title>
    <Typography.Paragraph className={'text'}>{'Your cash'}</Typography.Paragraph>
    <PrimaryButton text={'Withdraw'} />
  </div>
)
