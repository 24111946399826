import { CheckCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { PrimaryButton } from "globalComponents";

export const CourseDetailTitle = ({ courseDetail }) => {
    console.log(courseDetail)
  return (
    <div className={"course-title-btn-container"}>
      <div className={"course-title-wrapper"}>
        <Typography.Title className={"course-detail-title"}>
          {courseDetail?.course.name}
        </Typography.Title>
        <div className={"desc-container"}>
          <Typography.Paragraph className={"desc-text"}>
            <span className="by-text">{"By"}</span>{" "}
            {courseDetail?.instructor?.name}
          </Typography.Paragraph>
          <CheckCircleFilled
            className={`desc-icon ${courseDetail?.instructor?.verification}`}
          />
        </div>
      </div>
      <PrimaryButton text={`$${courseDetail?.course.price}`} />
    </div>
  );
};
