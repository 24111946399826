import { Col, Form, Row, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { forwardRef, useImperativeHandle } from "react";

const FinaliseCourseContent = ({ finalInfo }, ref) => {
  const [form] = useForm();

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit: () => form.submit(),
    }),
    [form]
  );

  return (
    <Form form={form} layout="vertical" className="form-style-1 mb-5">
      <Typography.Title className="font-bold" level={4}>
        Add A New Course
      </Typography.Title>
      <Row gutter={[30, 20]}>
        <Col xs={24} md={12}>
          <Space direction="vertical">
            <Space direction="vertical">
              <Typography.Text className="font-semibold primary-text-color course-detail-label">
                Course Name
              </Typography.Text>
              <Typography.Text className="course-detail-value">
                {finalInfo?.name}
              </Typography.Text>
            </Space>
            <Space direction="vertical">
              <Typography.Text className="font-semibold primary-text-color course-detail-label">
                Instructor
              </Typography.Text>
              <Typography.Text className="course-detail-value">
                {finalInfo?.instructorName}
              </Typography.Text>
            </Space>
            <Space direction="vertical">
              <Typography.Text className="font-semibold primary-text-color course-detail-label">
                Price
              </Typography.Text>
              <Typography.Text className="course-detail-value">
                {finalInfo?.price}
              </Typography.Text>
            </Space>
          </Space>
        </Col>
        <Col xs={24} md={12}>
          <Typography.Text className="font-semibold primary-text-color course-detail-label">
            Cirriculam
          </Typography.Text>
          {finalInfo.modules.map((module, moduleIndex) => (
            <div key={moduleIndex}>
              <Typography.Title level={5} style={{ marginTop: 8 }}>
                {module?.name}
              </Typography.Title>
              <div className="course-detail-grid">
                {module.lessons.map((lesson, lessonIndex) => (
                  <Typography.Text className="text-small" key={lessonIndex}>
                    {lesson?.name}
                  </Typography.Text>
                ))}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(FinaliseCourseContent);
