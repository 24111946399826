import { Typography } from 'antd'
import React from 'react'

const height = 60

export const AffiliateSubscriptions = () => {
  return (
    <div className={'as-subscription-container'}>
      <SubCard height={height + 90} cardType={'silver'} />
      <SubCard height={height + 45} cardType={'gold'} />
      <SubCard height={height} cardType={'platinum'} />
    </div>
  )
}

export const SubCard = ({ height, cardType }) => {
  return (
    <div className={'as-sub-card-container'} style={{ marginBottom: -height }}>
      <div className={`top-title-container ${cardType}`}>
        <Typography.Paragraph className={`title`}>{cardType}</Typography.Paragraph>
      </div>
      <div className={'bottom-desc-container'}>
        <Typography.Title level={3} className='highlighted'>{'SilverGet $10 per referral'}</Typography.Title>
      </div>
    </div>
  )
}
