import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Grid, Space, Typography } from "antd";
import { APP_LOGO, downArrowSvg } from "assets";
import { dummyImage } from "assets/constants";
import useUser from "context/user/useUser";
import {useEffect, useState} from "react";
import userProvider from "../../../../context/user/userProvider";

const { useBreakpoint } = Grid;

const DashboardHeader = ({ openDrawer }) => {
  // const { user, onLogoutUser } = useUser();

  const items = [
    {
      label: "Logout",
      onClick: handleUserLogout,
    },
  ];

  const { lg, md } = useBreakpoint() ?? [];

  const [user, setUser] = useState();

  function initializeComponent() {
    const currentUser = userProvider.getCurrentUser()
    if (currentUser) {
      setUser(currentUser);
    }
  }

  function handleUserLogout() {
    userProvider.signOutUser();
    window.location.reload();
  }

  useEffect(() => {
    initializeComponent();
  },[]);

  return (
    <header className="dashboard-header">
      {user && (
          <>
            <div className="header-left">
              <div
                  className={`header-hamburger ${!lg ? "show-header-hamburger" : ""}`}
              >
                <div className={"hamburger-container"} onClick={openDrawer}>
                  <MenuOutlined/>
                </div>
              </div>
              <img src={APP_LOGO} alt="app-logo"/>
              {md ? (
                  <div className={"header-title-container"}>
                    <Typography.Title className={"header-title font-bold"} level={5}>
                      {"Corsa University"}
                    </Typography.Title>
                  </div>
              ) : null}
            </div>
            <div className={`header-right ${!md ? "header-right-md-down" : ""}`}>
              <div className={"header-right-container"}>
                <Avatar
                    className={"header-right-avatar"}
                    src={user?.photo ? user?.photo : (dummyImage)}
                    icon={<UserOutlined/>}
                />
                <Dropdown menu={{items}} trigger={["click"]}>
                  <Space>
                    {md ? (
                        <Typography.Paragraph
                            className={"header-right-text cursor-pointer"}
                        >
                          {user?.name}
                        </Typography.Paragraph>
                    ) : null}
                    {downArrowSvg}
                  </Space>
                </Dropdown>
              </div>
            </div>
          </>
      )}
    </header>
  );
};

export default DashboardHeader;
