import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { ImagePicker } from "globalComponents";
import { forwardRef } from "react";
import { formRules } from "./formRules";
import useAddNewCourses from "./useAddNewCourses";

const AddNewCourse = ({ instructorOptions, getInstructorsApiLoading }, ref) => {
  const { form } = useAddNewCourses({ ref });

  return (
    <Form form={form} layout="vertical" className="form-style-1">
      <Row className="h-100" gutter={[10, 20]}>
        <Col xs={24} sm={11}>
          <ImagePicker
            title={"Add Thumbnail"}
            description={"1204 x 1204"}
            name={"thumbnail"}
            rules={formRules.thumbnail}
            form={form}
          />
        </Col>
        <Col className="h-100" xs={24} sm={13}>
          <Space>
            <Typography.Title className="font-bold" level={4}>
              Add A New Course
            </Typography.Title>
          </Space>

          <Form.Item label="Name" name={"name"} rules={formRules.name}>
            <Input className="input-style-1" placeholder="type here" />
          </Form.Item>
          <Form.Item
            label="Description"
            name={"description"}
            rules={formRules.description}
          >
            <Input className="input-style-1" placeholder="type here" />
          </Form.Item>
          <Row gutter={[10, 0]}>
            <Col xs={14}>
              <Form.Item
                label="Intructor"
                name={"instructors_id"}
                rules={formRules.instructors_id}
              >
                <Select
                  options={instructorOptions}
                  className="input-style-1"
                  loading={getInstructorsApiLoading}
                />
              </Form.Item>
            </Col>
            <Col xs={10}>
              <Form.Item name={"price"} label="Price" rules={formRules.price}>
                <InputNumber className="input-style-1 w-100" controls={false} />
              </Form.Item>
            </Col>
            {/* <Form.Item noStyle name={"thumbnail"}></Form.Item> */}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(AddNewCourse);
