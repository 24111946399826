import { Col, Row, Space, Typography } from "antd";
import { PrimaryButton } from "globalComponents";
import { CustomInput } from "globalComponents";
import { getFormRules } from "./formRules";
import useRegister from "./useRegister";
import "./register.scss";
import { FaEye,FaEyeSlash} from "react-icons/fa";
import {NavLink, useLocation, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

const RegisterPage = () => {

  const { handleRegisterUserFormSubmit, errors, register, watch, loading } =
    useRegister();

  const location = useLocation();

  const [showPassword, setShowPass] = useState(false);
  const formRules = getFormRules(register, watch);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    console.log(id);

  },[]);

  return (
    <div className=" register-area">
      <Row>
        <Col className="flex flex-col gutter-row left-content " span={12}>
          <div className="relative flex flex-col items-start justify-between w-full h-full p-10">
            <div className="left-content-2">
              <div className="relative z-10 w-full top-content">
                <h1>Create a new account</h1>
                <p>Enter your Personal details to create a new account</p>
                <form onSubmit={handleRegisterUserFormSubmit} className="pt-10">
                  <div className="mb-3 w-full ">
                    <div className="relative">
                      <CustomInput
                          placeholder={"email@example.com"}
                          error={errors["email"]}
                          {...formRules["email"]}
                      />
                      <div className="absolute flex items-center h-full top-1 left-3 icon">
                        <img src="/img/sms.svg" alt="sms" className=""/>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 w-full ">
                    <div className="relative">
                      <CustomInput
                          placeholder={"8 characters minimum"}
                          error={errors["username"]}
                          {...formRules["username"]}
                      />
                      <div className="absolute flex items-center h-full top-1 left-3 icon">
                        <img src="/img/icon/user.svg" alt="sms"/>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 w-full ">
                    <div className="relative">
                      <CustomInput
                          error={errors["mobile"]}
                          {...formRules["mobile"]}
                      />
                      <div className="absolute flex items-center h-full top-1 left-3 icon">
                        <img src="/img/icon/call.svg" alt="sms"/>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 w-full">
                    <div className="relative">
                      <CustomInput
                          type={showPassword ? "text" : "password"}
                          error={errors["password"]}
                          {...formRules["password"]}
                      />
                      <div className="absolute top-0 flex items-center h-full left-3 icon">
                        <img src="/img/lock.svg" alt="sms"/>
                      </div>
                      <div className="absolute flex items-center h-full top-1 right-4 icon">
                        <button
                            type="btn"
                            className="btn"
                            onClick={() => setShowPass(!showPassword)}
                        >
                          {showPassword ? <FaEyeSlash/> : <FaEye/>}
                        </button>
                      </div>
                    </div>
                  </div>

                  <PrimaryButton
                      htmlType="submit"
                      loading={loading}
                      text="Sign Up"
                      className="auth-btn"
                  />
                </form>
              </div>
            </div>
            <div className="relative z-10 w-full bottom-content">
              <div className="flex items-center divider-list">
                <div style={{marginBottom: '20px'}} className="divider"></div>
              </div>
              <div className="signup-text">
                <NavLink to="/auth/login">
                  Already have an account? <strong>Sign in</strong>
                </NavLink>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 overly-effect ">
              <img src="/img/login/effect.png" alt="effect"/>
            </div>
          </div>
        </Col>
        <Col
            className="flex flex-col items-center justify-center h-full gutter-row"
            span={12}
        >
          <div className="relative flex items-center justify-center w-full right-content">
            <img
                src="/img/SignUp.png"
                alt="effect2"
                className="effect"
            />
            <div
                className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full overly-box">
              <img src="/img/login/logo.png" alt=""/>
              <h2>Corsa University</h2>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterPage;
