import { ChatContainer } from './components/ChatContainer'
import { ChatHeader } from './components/ChatHeader'

const locked = false;

const Chat = () => {
  return (
    <div className={'main-chat-container'}>
      <ChatHeader locked={locked} />
      <ChatContainer locked={locked} />
    </div>
  )
}

export default Chat
