import { Col, Row } from "antd";
import { CustomCard } from "globalComponents";
import { useNavigate } from "react-router-dom";
import { getServerUrlAttached } from "utils";

export const CoursesList = ({ courses }) => {
  const navigate = useNavigate();

  const column = (val, index) => (
    <Col key={index} xs={12} sm={8} md={8} lg={8} xl={6} xxl={6}>
      <CustomCard
        // bandText={index === 0 ? "Starter" : undefined}
        primaryText={val?.course.name}
        secondaryText={val?.instructor?.name}
        secondaryTickColor={val?.instructor?.verification}
        // selected={index === 0}
        imgSrc={val?.course.photo}
        onClick={() => navigate(`/courses/detail/${val.course.course_id}`)}
        arrowText={"Learn More"}
      />
    </Col>
  );

  return (
    <div className={"cards-list-container"}>
      <Row className="w-100" gutter={[10, 20]}>{courses.map(column)}</Row>
    </div>
  );
};
