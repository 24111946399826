
import { WithTheme, WithNetworkAccess } from "hoc";
import RouterConfig from "navigation";

const App = () => {

  return (
    <WithTheme>
      <WithNetworkAccess>
        <RouterConfig />
      </WithNetworkAccess>
    </WithTheme>
  );
};

export default App;
