import { imageExtensions } from "utils";
import { videoExtensions } from "utils";

export const CourseDetailVideo = ({ imageUrl }) => {
  return (
    <div className={"course-detail-container"}>
      <div className={"course-video-wrapper"}>
        <MediaComponent src={imageUrl} className={"video-box"} />
      </div>
    </div>
  );
};

const MediaComponent = ({ src, className }) => {
  const fileExtension = src.substr(src.lastIndexOf(".") + 1)?.toLowerCase();

  if (videoExtensions.includes(fileExtension)) {
    return <video style={{ objectFit: 'cover'}} src={src} controls className={className} />;
  } else if (imageExtensions.includes(fileExtension)) {
    return <img src={src} alt="img" className={className} />;
  } else {
    return <p>Unsupported file format</p>;
  }
};
