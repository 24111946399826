import { DefaultLayout, AuthLayout } from "layouts";
import {
  AffiliatePage,
  WelcomePage,
  EducatorPage,
  CourseDetailsPage,
  MentorshipDetailsPage,
  ServicesPage,
  AutomationsPage,
  MentorshipPage,
  CoursesPage,
  FAQPage,
  NotFoundPage,
  DashboardPage,
  AdminPage,
  AccountPage,
  Appointment,
  EducatorLanding,
} from "pages/app";

import { LoginPage, RegisterPage } from "pages/auth";
import CheckOutPage from "pages/auth/Checkout/Index";
import PackagePage from "pages/auth/Package/Package";
import VerifyPage from "pages/auth/Verify";
import { Navigate } from "react-router-dom";

const getRoutes = (isAuthenticated) => [
  {
    path: "welcome",
    element: <WelcomePage />,
  },
  {
    element: isAuthenticated ? <Navigate to="/" replace /> : <AuthLayout />,
    path: "auth",
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "package",
        element: <PackagePage />,
      },
      {
        path: "checkout",
        element: <CheckOutPage />,
      },
      {
        path: "verify",
        element: <VerifyPage />,
      },
    ],
  },
  {
    path: "",
    element: !isAuthenticated ? (
      <Navigate to="/auth/login" replace />
    ) : (
      <DefaultLayout />
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/courses",
        element: <CoursesPage />,
      },
      {
        path: "/courses/:instructorName/:instructorId",
        element: <CoursesPage />,
      },
      {
        path: "/courses/detail/:courseId",
        element: <CourseDetailsPage />,
      },
      {
        path: "/educator",
        element: <EducatorPage />,
        children: [
          {
            index: true,
            element: <EducatorLanding />,
          },
          {
            path: "detail/:instructorId",
            element: <Appointment />,
          },
        ],
      },
      {
        path: "/mentorship",
        element: <MentorshipPage />,
      },
      {
        path: "/mentorship/detail/:userId",
        element: <MentorshipDetailsPage />,
      },
      {
        path: "/services",
        element: <ServicesPage />,
      },
      {
        path: "/automations",
        element: <AutomationsPage />,
      },
      {
        path: "/affiliate",
        element: <AffiliatePage />,
      },
      {
        path: "/faqs",
        element: <FAQPage />,
      },
      {
        path: "/account",
        element: <AccountPage />,
      },
      {
        path: "/admin",
        element: <AdminPage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <NotFoundPage />,
    loader: () => {
      return false;
    },
  },
];

export default getRoutes;
