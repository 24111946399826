import useMessage from "antd/es/message/useMessage";
import { useLaterEffect } from "hooks";
import { useEffect, useState } from "react";

const WithNetworkAccess = ({ children }) => {
  const [messageApi, messageContext] = useMessage();
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", (e) => {
      setIsOnline(false);
    });

    window.addEventListener("online", (e) => {
      setIsOnline(true);
    });
    return () => {
      window.removeEventListener("offline", () => {});
      window.removeEventListener("online", () => {});
    };
  }, [messageApi]);

  useLaterEffect(() => {
    isOnline
      ? messageApi.success("You are back online")
      : messageApi.warning(
          "You are offline. Please check your internet connection"
        );
  }, [isOnline]);

  return (
    <>
      {messageContext}
      {children}
    </>
  );
};

export default WithNetworkAccess;
