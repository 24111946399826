export const educatorThumbnailSvg = (
  <svg
    width='434.64'
    height='397.021'
    viewBox='0 0 434.64 397.021'
  >
    <g id='Group_9590' data-name='Group 9590' transform='translate(-32.941 -53.125)'>
      <circle
        id='Ellipse_79'
        data-name='Ellipse 79'
        cx='165.632'
        cy='165.632'
        r='165.632'
        transform='translate(57.507 53.125)'
        fill='#282828'
      />
      <g id='Group_9574' data-name='Group 9574'>
        <g id='Group_9571' data-name='Group 9571'>
          <path
            id='Path_18184'
            data-name='Path 18184'
            d='M247.051,280.015s-46.17,56.03-62.5,54.57-27.52-28.77-27.52-28.77.56-.3,1.51-.87c4.78-2.82,19.48-12.16,22.92-22.13,3.33-5.41,27.55-44.68,34.92-54.7,7.93-10.77,17.26-11.19,17.26-11.19,5.26,8.65,11.9,15.23,20.13,16.47a32.529,32.529,0,0,0,3.28.33c.5,3.52,3.81,21.48,19.94,30.67,0,0,.62-12.51,8.89-13.62,0,0,8.76-4.8,15.99,2.22,0,0,11.46-12.3,0-46.84l-8.18-7.4-.01-.01-3.75-8.92s41.85-2.09,51.53,6.88,71.6,90.9,71.6,90.9l-90.07,69.93S268.381,303.375,247.051,280.015Z'
            fill='#fff'
          />
          <path
            id='Path_18185'
            data-name='Path 18185'
            d='M301.871,206.155c11.46,34.54,0,46.84,0,46.84-7.23-7.02-15.99-2.22-15.99-2.22l11.68-42.82-3.87-9.2Z'
            fill='#ffedc5'
          />
          <path
            id='Path_18186'
            data-name='Path 18186'
            d='M273.771,242.785l12.11,7.99c-8.27,1.11-8.89,13.62-8.89,13.62-16.13-9.19-19.44-27.15-19.94-30.67a26.257,26.257,0,0,0,2.95-.02Z'
            fill='#ffedc5'
          />
          <path
            id='Path_18187'
            data-name='Path 18187'
            d='M246.771,279.565q-7.557-9.328-15.115-18.655c-.4-.5-1.109.211-.707.707l15.115,18.655C246.469,280.772,247.173,280.06,246.771,279.565Z'
            fill='#2e303d'
          />
          <path
            id='Path_18188'
            data-name='Path 18188'
            d='M242.356,280.789l-10.7-4.592c-.585-.252-1.1.61-.505.863l10.7,4.593C242.436,281.9,242.946,281.042,242.356,280.789Z'
            fill='#2e303d'
          />
          <path
            id='Path_18189'
            data-name='Path 18189'
            d='M321.972,309.31l-13.6-22.5q-6.77-11.2-13.54-22.4-3.837-6.345-7.673-12.692a.5.5,0,0,0-.864.505l13.6,22.505,13.541,22.4,7.673,12.692A.5.5,0,0,0,321.972,309.31Z'
            fill='#2e303d'
          />
        </g>
        <g id='Group_9572' data-name='Group 9572'>
          <path
            id='Path_18190'
            data-name='Path 18190'
            d='M182.191,279.124a12.718,12.718,0,0,1-.73,3.691c-3.44,9.97-18.14,19.309-22.92,22.129l-1.51-5.529s-7.75,1.74-13.42-3.17a1.955,1.955,0,0,1-.67.73,4.518,4.518,0,0,1-2.75.41,22.521,22.521,0,0,1-11.76-4.64,11.1,11.1,0,0,1-4.33-6.02,5.769,5.769,0,0,1,2.43-6.24,7.151,7.151,0,0,1-.15-1.13,7.9,7.9,0,0,1,2.6-5.77,21.248,21.248,0,0,1,4.97-3.391c.02-.089.04-.169.06-.25a7.324,7.324,0,0,1,2.81-3.77,20.569,20.569,0,0,1,3.8-2.079c-6.41-3.3-12.56-6.96-11.94-8.6,1.25-3.33,14.38-2.15,27.66,2.5s17.61,11.6,17.61,11.6C178.081,267.765,182.6,268.124,182.191,279.124Z'
            fill='#ff9971'
          />
          <path
            id='Path_18191'
            data-name='Path 18191'
            d='M177.325,281l-9.283,3.983-4.7,2.016c-1.288.553-2.855,1.453-4.3,1.006-1.556-.482-1.573-2.224-.977-3.473a18.709,18.709,0,0,1,2.389-3.461,39.281,39.281,0,0,1,13.677-10.857c.58-.279.073-1.142-.5-.864a40.257,40.257,0,0,0-14.133,11.334c-1.47,1.868-4.239,5.165-2.033,7.505a3.813,3.813,0,0,0,3.932.668,49.579,49.579,0,0,0,4.746-1.977l11.69-5.016C178.419,281.608,177.911,280.746,177.325,281Z'
            fill='#2e303d'
          />
          <path
            id='Path_18192'
            data-name='Path 18192'
            d='M152.932,271.013l-11.639-6.949a.5.5,0,0,0-.505.864l11.639,6.949A.5.5,0,0,0,152.932,271.013Z'
            fill='#2e303d'
          />
          <path
            id='Path_18193'
            data-name='Path 18193'
            d='M154.742,289.747a10.039,10.039,0,0,0-.9-2.689l-1.2-2.865q-2.754-6.591-5.509-13.182c-.244-.586-1.212-.329-.964.265l4.455,10.661q1.127,2.694,2.251,5.387c.456,1.09,1.858,3.958-.165,4.421-1.418.325-3.073-1.091-4.149-1.849a35.141,35.141,0,0,1-3.545-2.859,36.718,36.718,0,0,1-5.853-6.874,36.245,36.245,0,0,1-4.347-9.21c-.19-.613-1.155-.351-.965.266a37.4,37.4,0,0,0,14.205,19.541c1.245.876,2.736,2.044,4.339,2.019A2.534,2.534,0,0,0,154.742,289.747Z'
            fill='#2e303d'
          />
          <path
            id='Path_18194'
            data-name='Path 18194'
            d='M146.339,290.271c-.383-.507-1.252-.009-.863.505a2.275,2.275,0,0,1-.05,2.97,2.524,2.524,0,0,1-2.964.412,13.716,13.716,0,0,1-2.8-1.9q-1.366-1.074-2.683-2.209a76.868,76.868,0,0,1-9.572-9.9c-.4-.5-1.108.211-.707.707a76.808,76.808,0,0,0,8.729,9.16c1.538,1.367,3.13,2.679,4.778,3.912,1.448,1.084,3.34,2.117,5.12,1.143A3.252,3.252,0,0,0,146.339,290.271Z'
            fill='#2e303d'
          />
        </g>
        <g id='Group_9573' data-name='Group 9573'>
          <path
            id='Path_18195'
            data-name='Path 18195'
            d='M297.561,207.955l-11.68,42.82-12.11-7.99c12.46-17.7,10.03-38.72,10.03-38.72s-1.94,28.309-23.8,29.64a26.257,26.257,0,0,1-2.95.02,32.529,32.529,0,0,1-3.28-.33c-8.23-1.24-14.87-7.821-20.13-16.47-10.45-17.181-15.5-42.481-16.87-50.25,12.31-1.61,24.27-6.78,35.95-11.44,3.61-1.441,7.42-2.871,11.29-2.441,3.96.431,7.51,2.791,10.17,5.761a43.539,43.539,0,0,1,5.71,8.76c.46-2.821,2.37-11.28,8.77-8.84a5.6,5.6,0,0,1,1.97,1.3c5.31,5.36,3.36,22-2.66,25.39l1.96,4.659,3.75,8.92.01.011Z'
            fill='#ff9971'
          />
          <path
            id='Path_18196'
            data-name='Path 18196'
            d='M283.8,204.065s2.43,21.02-10.03,38.72L260,233.705C281.861,232.375,283.8,204.065,283.8,204.065Z'
            fill='#2e303d'
          />
          <path
            id='Path_18197'
            data-name='Path 18197'
            d='M289.551,144.654c-.59-3.759-1.51-7.669-4.02-10.529-2.249-2.553-6.136-3.911-9.186-2.654a13.415,13.415,0,0,0-9.589-16.456c-8.478-2.208-16.223,3.372-23.108,7.445a41.858,41.858,0,0,1-11.695,4.908,37.736,37.736,0,0,1-18.7-.3,34.732,34.732,0,0,0-6.212-1.35c-3.529-.278-7.125,1.058-8.545,4.507-1.323,3.213-.643,6.963.644,10.071a20.868,20.868,0,0,0,3.762,5.951,9.942,9.942,0,0,0-8.064,17.061c2.75,2.63,6.73,3.469,10.52,3.75a55.009,55.009,0,0,0,11.41-.38c12.31-1.61,24.27-6.78,35.95-11.44,3.61-1.441,7.42-2.87,11.29-2.441,3.96.431,7.51,2.79,10.17,5.761a43.511,43.511,0,0,1,5.71,8.76c.46-2.82,2.37-11.281,8.77-8.84a5.606,5.606,0,0,1,1.97,1.3A89.09,89.09,0,0,0,289.551,144.654Zm-21.23-24.529c-4.78-1.721-10.14-.6-14.8,1.419s-8.92,4.87-13.6,6.821a38.712,38.712,0,0,1-27.13.84c-3.24-1.121-6.78-2.681-9.93-1.34-2.442,1.039-3.8,3.6-4.06,6.288a10.636,10.636,0,0,1,.13-1.8,6.719,6.719,0,0,1,2.252-4.17,7.774,7.774,0,0,1,4.984-1.5,23.547,23.547,0,0,1,6.037,1.137,43.882,43.882,0,0,0,5.826,1.232,39.091,39.091,0,0,0,22.525-3.74c7.1-3.547,13.586-9.487,21.9-9.752a12.983,12.983,0,0,1,9.9,3.879,12.492,12.492,0,0,1,3.33,10.3c-.017.129-.054.255-.076.383C275.794,125.853,272.468,121.628,268.321,120.125Z'
            fill='#3a382c'
          />
          <path
            id='Path_18198'
            data-name='Path 18198'
            d='M268.511,204.4s5.56,10.16-2.92,16.06-14.46-2.48-14.46-2.48Z'
            fill='#fff'
          />
          <path
            id='Path_18199'
            data-name='Path 18199'
            d='M239.6,185.995l11.53,22.94s-9.38,5.28-11.53,2.22S239.6,185.995,239.6,185.995Z'
            fill='#f9785a'
          />
          <path
            id='Path_18200'
            data-name='Path 18200'
            d='M235.356,191.174c.885,2.994-.084,5.919-2.164,6.533s-4.483-1.314-5.367-4.308.084-5.919,2.164-6.534S234.472,188.18,235.356,191.174Z'
            fill='#2e303d'
          />
          <path
            id='Path_18201'
            data-name='Path 18201'
            d='M259.689,182.966c.884,2.994-.085,5.919-2.164,6.533s-4.483-1.314-5.368-4.308.085-5.919,2.165-6.533S258.8,179.972,259.689,182.966Z'
            fill='#2e303d'
          />
          <path
            id='Path_18202'
            data-name='Path 18202'
            d='M230.3,179.06a21.515,21.515,0,0,0-5.427,2.136,2.016,2.016,0,0,0-.643,2.755,2.045,2.045,0,0,0,2.755.643c.422-.234.856-.444,1.294-.647a19.649,19.649,0,0,1,3.154-1.051,2.013,2.013,0,0,0,1.352-2.485A2.045,2.045,0,0,0,230.3,179.06Z'
            fill='#2e303d'
          />
          <path
            id='Path_18203'
            data-name='Path 18203'
            d='M258.8,172.334a2.336,2.336,0,0,0-1.407-.543,15.343,15.343,0,0,0-7.2,1.063,2.162,2.162,0,0,0-1.19.962,2,2,0,0,0,2.323,2.874,12.262,12.262,0,0,1,6.14-.9,2.014,2.014,0,0,0,1.421-.63,2,2,0,0,0-.087-2.828Z'
            fill='#2e303d'
          />
          <path
            id='Path_18204'
            data-name='Path 18204'
            d='M276.076,114.651c-.254-.589-1.116-.081-.863.5a33.633,33.633,0,0,1,2.732,13.654.5.5,0,0,0,1,0A34.813,34.813,0,0,0,276.076,114.651Z'
            fill='#2e303d'
          />
          <path
            id='Path_18205'
            data-name='Path 18205'
            d='M280.656,123.274q-.641,2.638-1.28,5.275c-.152.625.812.892.964.266q.641-2.637,1.28-5.275C281.772,122.915,280.808,122.648,280.656,123.274Z'
            fill='#2e303d'
          />
        </g>
      </g>
      <g id='Group_9581' data-name='Group 9581'>
        <g id='Group_9575' data-name='Group 9575'>
          <path
            id='Path_18206'
            data-name='Path 18206'
            d='M273.86,419.442v6.39H184.64l-68.01-36.97v-5.75l69.31,36.33Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18207'
            data-name='Path 18207'
            d='M273.86,419.442H185.94l-69.31-36.33h87.92Z'
            fill='#b7b7b7'
          />
          <path
            id='Path_18208'
            data-name='Path 18208'
            d='M195.245,416.648H184.418l-8.535-4.474H186.71Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18209'
            data-name='Path 18209'
            d='M183.8,410.67H172.976l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18210'
            data-name='Path 18210'
            d='M174.122,404.927H163.3l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18211'
            data-name='Path 18211'
            d='M162.68,398.949H151.853l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18212'
            data-name='Path 18212'
            d='M208.312,415.779H197.485l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18213'
            data-name='Path 18213'
            d='M198.63,410.036H187.8l-8.535-4.474H190.1Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18214'
            data-name='Path 18214'
            d='M187.189,404.058H176.362l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18215'
            data-name='Path 18215'
            d='M210.674,408.97H199.847l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18216'
            data-name='Path 18216'
            d='M200.993,403.226H190.166l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18217'
            data-name='Path 18217'
            d='M189.552,397.249H178.725l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18218'
            data-name='Path 18218'
            d='M250.557,417.188H239.73l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18219'
            data-name='Path 18219'
            d='M239.115,411.211H228.288l-8.535-4.474H230.58Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18220'
            data-name='Path 18220'
            d='M229.434,405.467H218.607l-8.535-4.474H220.9Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18221'
            data-name='Path 18221'
            d='M217.993,399.49H207.166l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18222'
            data-name='Path 18222'
            d='M263.624,416.319H252.8l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18223'
            data-name='Path 18223'
            d='M253.943,410.576H243.116l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18224'
            data-name='Path 18224'
            d='M242.5,404.6H231.674l-8.535-4.474h10.827Z'
            fill='#8e8e8e'
          />
        </g>
        <g id='Group_9580' data-name='Group 9580'>
          <path
            id='Path_18225'
            data-name='Path 18225'
            d='M330.029,450.146h-87.92l-44.891-23.578h87.92Z'
            fill='#d5e0e0'
          />
          <path
            id='Path_18226'
            data-name='Path 18226'
            d='M269.054,447.315H243.466L230.4,440.453h25.588Z'
            fill='#826b36'
          />
          <g id='Group_9576' data-name='Group 9576'>
            <path
              id='Path_18227'
              data-name='Path 18227'
              d='M277.043,446.883a1,1,0,0,1-.464-.114l-30.341-15.935a1,1,0,1,1,.93-1.772L277.509,445a1,1,0,0,1-.466,1.886Z'
              fill='#b7b7b7'
            />
          </g>
          <g id='Group_9577' data-name='Group 9577'>
            <path
              id='Path_18228'
              data-name='Path 18228'
              d='M287.25,446.3a1.011,1.011,0,0,1-.464-.115l-30.341-15.935a1,1,0,0,1,.93-1.772l30.341,15.936a1,1,0,0,1-.466,1.886Z'
              fill='#b7b7b7'
            />
          </g>
          <g id='Group_9578' data-name='Group 9578'>
            <path
              id='Path_18229'
              data-name='Path 18229'
              d='M301.127,447.177a1,1,0,0,1-.463-.114l-30.341-15.936a1,1,0,0,1,.929-1.771l30.341,15.935a1,1,0,0,1-.466,1.886Z'
              fill='#b7b7b7'
            />
          </g>
          <g id='Group_9579' data-name='Group 9579'>
            <path
              id='Path_18230'
              data-name='Path 18230'
              d='M311.335,446.589a1.006,1.006,0,0,1-.464-.114L280.53,430.54a1,1,0,0,1,.93-1.772L311.8,444.7a1,1,0,0,1-.466,1.885Z'
              fill='#b7b7b7'
            />
          </g>
        </g>
      </g>
      <g id='Group_9583' data-name='Group 9583'>
        <path
          id='Path_18231'
          data-name='Path 18231'
          d='M403.006,181.275l17.111-8.908a37.881,37.881,0,1,0-17.4-10.022Z'
          fill='#282828'
        />
        <g id='Group_9582' data-name='Group 9582'>
          <path
            id='Path_18232'
            data-name='Path 18232'
            d='M440.347,161.987v2.482H421.535v-7.095Z'
            fill='#8e8e8e'
          />
          <path
            id='Path_18233'
            data-name='Path 18233'
            d='M423.759,164.469h14.448s-.341,2.989-7.27,2.989S423.759,164.469,423.759,164.469Z'
            fill='#b7b7b7'
          />
          <path
            id='Path_18234'
            data-name='Path 18234'
            d='M440.347,155.234v6.754l-18.812-4.613v-2.141h18.812Z'
            fill='#b7b7b7'
          />
          <path
            id='Path_18235'
            data-name='Path 18235'
            d='M453.338,125.48a22.18,22.18,0,0,1-11.007,19.172l-2.906,10.582H422.882l-2.9-10.582a22.184,22.184,0,1,1,33.353-19.172Z'
            fill='#cdb26f'
          />
          <path
            id='Path_18236'
            data-name='Path 18236'
            d='M422.25,130.624c-.083-.529-.887-.3-.8.222q1.858,11.925,3.718,23.848c.082.529.887.3.8-.222Q424.11,142.548,422.25,130.624Z'
            fill='#fff'
          />
          <path
            id='Path_18237'
            data-name='Path 18237'
            d='M439.843,129.817q-2.005,12.552-4.013,25.1c-.084.525.721.752.806.222q2.006-12.554,4.012-25.1C440.732,129.513,439.928,129.287,439.843,129.817Z'
            fill='#fff'
          />
          <path
            id='Path_18238'
            data-name='Path 18238'
            d='M442.1,135.862a4.032,4.032,0,0,1-4.393,2.549,5.536,5.536,0,0,1-3.526-2.758,10.809,10.809,0,0,0,1.571-3.507,10,10,0,0,0,.2-3.77c-.135-.956-.584-2.176-1.687-2.325-1.289-.174-1.95,1.124-2.159,2.187a11.324,11.324,0,0,0-.024,4.161,11.1,11.1,0,0,0,1.073,3.156c-.11.136-.218.273-.336.4a4.181,4.181,0,0,1-2.726,1.569,2.628,2.628,0,0,1-2.326-1.416,7.509,7.509,0,0,0,1.144-2.693,16.334,16.334,0,0,0,.321-4.344c-.073-1.1-.834-2.392-2.125-2.021-1.326.381-1.548,2-1.635,3.158a10.515,10.515,0,0,0,.473,3.959,10.663,10.663,0,0,0,.815,1.921,3.569,3.569,0,0,1-2.156,1.237c-2.513.418-3.459-2.155-3.948-4.109a.418.418,0,0,0-.8.222c.542,2.168,1.6,4.773,4.244,4.763a4.244,4.244,0,0,0,3.113-1.4,4.812,4.812,0,0,0,.674.754,3.251,3.251,0,0,0,3.447.533,6.1,6.1,0,0,0,2.3-1.719,6.608,6.608,0,0,0,3.126,2.64,4.851,4.851,0,0,0,6.152-2.922C443.09,135.578,442.283,135.36,442.1,135.862Zm-15.728-6.246a2.522,2.522,0,0,1,.6-1.552c.511-.459,1.086-.16,1.309.411a4.424,4.424,0,0,1,.107,1.717c-.018.587-.041,1.176-.1,1.761a8.6,8.6,0,0,1-.979,3.354A9.707,9.707,0,0,1,426.373,129.616Zm7.271,5.035a10.221,10.221,0,0,1-.657-6.528,1.539,1.539,0,0,1,.872-1.217c.512-.156.857.28,1.043.7a6.171,6.171,0,0,1,.284,2.87,9.387,9.387,0,0,1-1.47,4.318C433.693,134.746,433.666,134.7,433.644,134.651Z'
            fill='#fff'
          />
        </g>
      </g>
      <g id='Group_9584' data-name='Group 9584'>
        <path
          id='Path_18239'
          data-name='Path 18239'
          d='M189.006,186.88l-17.8,2.761a35.376,35.376,0,1,1,7.548-17.163Z'
          fill='#fff'
        />
        <path
          id='Path_18240'
          data-name='Path 18240'
          d='M171.864,155.956,138.717,189.1,120.7,171.071l8.737-8.738,9.284,9.284,24.4-24.4Z'
          fill='#cdb26f'
        />
      </g>
      <g id='Group_9588' data-name='Group 9588'>
        <g id='Group_9585' data-name='Group 9585'>
          <path
            id='Path_18241'
            data-name='Path 18241'
            d='M379.111,265.677l-22.58,25.34-5.77,15.57-1.18-14.6-2.37-17.79.24-3.29,1.35-18.59a40.094,40.094,0,0,1-10.83-.37c-13.5-2.4-23.8-12.71-25.09-35.02-.79-13.59.89-23.83,3.16-31.21,3.33-10.84,7.96-15.49,7.96-15.49s16.89,28.29,39.27,31.42c0,0,4.1-9.67,10.49-6.82s6.4,22.09-3.74,24.61a83.608,83.608,0,0,0,5.61,39.36A38.957,38.957,0,0,0,379.111,265.677Z'
            fill='#ff9971'
          />
          <circle
            id='Ellipse_80'
            data-name='Ellipse 80'
            cx='9.05'
            cy='9.05'
            r='9.05'
            transform='translate(347.981 209.707)'
            fill='#f9785a'
          />
          <path
            id='Path_18242'
            data-name='Path 18242'
            d='M360.071,250.087l-11.98,11.96.71-9.73A57.9,57.9,0,0,0,360.071,250.087Z'
            fill='#2e303d'
          />
          <path
            id='Path_18243'
            data-name='Path 18243'
            d='M348.091,262.047l-.64,8.86s-12.98,5.82-18.93,19.57a25.937,25.937,0,0,1-.22-13.04,71.7,71.7,0,0,1,5.03-13.8,96.022,96.022,0,0,0,4.64-11.69,40.094,40.094,0,0,0,10.83.37Z'
            fill='#2e303d'
          />
          <path
            id='Path_18244'
            data-name='Path 18244'
            d='M326.641,205.947l5.15,21.69s-8.97,2.71-10.15.55S326.641,205.947,326.641,205.947Z'
            fill='#f9785a'
          />
          <path
            id='Path_18245'
            data-name='Path 18245'
            d='M452.856,270.4c-2.681-10.968-9.19-20.457-16.6-28.787-6-6.743-12.673-12.841-19-19.272-1.982-3.227-3.836-6.529-5.491-9.945-6.2-12.79-9.45-26.86-15.83-39.55s-17.1-24.52-31.14-26.71c-20.62-3.22-40.3,15.06-60.81,11.21a34.757,34.757,0,0,0,11.29,28.13l.77.24c2.857-9.3,6.662-14.033,7.688-15.193,1.234,6.141,5.5,11.192,9.718,15.653,4.934,5.217,10.489,10.066,17.234,12.758a27.813,27.813,0,0,0,9.132,2.008,31.333,31.333,0,0,0,3.458.7s4.1-9.67,10.49-6.82,6.4,22.09-3.74,24.61a83.608,83.608,0,0,0,5.61,39.36,38.957,38.957,0,0,0,3.48,6.88,19.9,19.9,0,0,0,3.01,3.64v.01c.41.38.84.75,1.3,1.1,8.79,6.71,21.74,2.69,32.09,6.58,9.58,3.6,15.52,13.68,17.08,23.8s-.47,20.41-2.49,30.45l.18.06c.2-.6.39-1.21.58-1.81,13.435-4.756,21.4-19.322,22.033-33.653A55.181,55.181,0,0,0,452.856,270.4ZM343.023,193.5a61.85,61.85,0,0,1-7.8-6.915,62.855,62.855,0,0,1-6.66-7.761,24.51,24.51,0,0,1-3.448-6.851c3.45,5.224,14.677,20.811,29.5,27.186A34.477,34.477,0,0,1,343.023,193.5ZM452.81,290.822c-.973-14.487-7.983-28.006-16.069-40.135-5.508-8.253-11.6-16.162-17.019-24.458,3.855,3.814,7.771,7.571,11.529,11.47,7.94,8.238,15.459,17.437,19.282,28.376A52.813,52.813,0,0,1,452.81,290.822Z'
            fill='#3a382c'
          />
          <path
            id='Path_18246'
            data-name='Path 18246'
            d='M322.869,206.136c0,3.122-1.758,5.652-3.927,5.652s-3.927-2.53-3.927-5.652,1.758-5.652,3.927-5.652S322.869,203.014,322.869,206.136Z'
            fill='#2e303d'
          />
          <path
            id='Path_18247'
            data-name='Path 18247'
            d='M343.22,205.325c0,3.122-1.758,5.653-3.927,5.653s-3.926-2.531-3.926-5.653,1.758-5.652,3.926-5.652S343.22,202.2,343.22,205.325Z'
            fill='#2e303d'
          />
          <path
            id='Path_18248'
            data-name='Path 18248'
            d='M321.452,193.086a21.534,21.534,0,0,0-5.81.51,2.016,2.016,0,0,0-1.4,2.461,2.045,2.045,0,0,0,2.46,1.4c.471-.1.947-.183,1.423-.254a19.744,19.744,0,0,1,3.324-.114,2.014,2.014,0,0,0,2-2A2.044,2.044,0,0,0,321.452,193.086Z'
            fill='#2e303d'
          />
          <path
            id='Path_18249'
            data-name='Path 18249'
            d='M345.38,194.877a2.334,2.334,0,0,0-1.2-.919,15.339,15.339,0,0,0-7.206-1.021,2.161,2.161,0,0,0-1.414.586,2,2,0,0,0,1.414,3.414,12.269,12.269,0,0,1,6.143.878,2,2,0,0,0,1.541-.2,2,2,0,0,0,.718-2.736Z'
            fill='#2e303d'
          />
          <path
            id='Path_18250'
            data-name='Path 18250'
            d='M346.9,234.977c1.45,3.583.087,7.514-3.045,8.781s-6.845-.609-8.294-4.192-.087-7.514,3.044-8.781S345.446,231.4,346.9,234.977Z'
            fill='#e56b45'
          />
          <path
            id='Path_18251'
            data-name='Path 18251'
            d='M347.981,230s2.99,8.55-5.63,11.4c-7.02,2.32-9.97-2.29-10.76-3.87Z'
            fill='#fff'
          />
        </g>
        <g id='Group_9587' data-name='Group 9587'>
          <path
            id='Path_18252'
            data-name='Path 18252'
            d='M430.1,331.257l.18.06c-2.78,8.63-5.94,17.29-9.13,25.46v.01c-9.86,25.24-20.02,45.83-20.33,46.44-9.74-13.45-27.88-22.38-33.77-25.03-1.21-.54-1.9-.82-1.9-.82l-3.71,11.12c.31.29.63.58.93.86l-33.63,4.48v-13.86s-.83-1.8-2.06-4.66c-1.68-3.9-4.11-9.78-6.14-15.7-2.23-6.5-3.98-13.05-3.77-17.08.56-10.38,23.3-50.41,23.3-50.41a44.691,44.691,0,0,0,1.31,10.43,6.75,6.75,0,0,1,.67-1.95c.1.43,3.32,13.71,9.33,23.77l12.8-21.82c.53,1.37,1.05,2.9,1.52,4.58,0,0,14.2-22.96,16.42-37.81.41.38.84.75,1.3,1.1,8.79,6.71,21.74,2.69,32.09,6.58,9.58,3.6,15.52,13.68,17.08,23.8S432.121,321.217,430.1,331.257Z'
            fill='#cdb26f'
          />
          <path
            id='Path_18253'
            data-name='Path 18253'
            d='M421.151,356.787l5.99,52.65h-26.98l2.24-3.89c-.49-.79-1.02-1.56-1.58-2.32C401.131,402.617,411.291,382.027,421.151,356.787Z'
            fill='#cdb26f'
          />
          <path
            id='Path_18254'
            data-name='Path 18254'
            d='M400.821,403.227c.56.76,1.09,1.53,1.58,2.32l-2.24,3.89-11.29,19.65s-3.97-18.67-26.5-39.73c-.3-.28-.62-.57-.93-.86l3.71-11.12s.69.28,1.9.82C372.941,380.847,391.081,389.777,400.821,403.227Z'
            fill='#826b36'
          />
          <path
            id='Path_18255'
            data-name='Path 18255'
            d='M388.871,429.087s-25.83.46-98.04-3.71c0,0-16.87,11.68-23.72,10.94s-20.39-13.44-23.73-18.72,5.38-13.16,5.38-13.16.09-3.98,5.56-6.3c2.44-1.03,9.13-.89,15.81-.42,8.32.6,16.62,1.72,16.62,1.72l20.09-2.68,21.9-2.92,33.63-4.48C384.9,410.417,388.871,429.087,388.871,429.087Z'
            fill='#ff9971'
          />
          <path
            id='Path_18256'
            data-name='Path 18256'
            d='M382.121,269.317v.01c-2.22,14.85-16.42,37.81-16.42,37.81-.47-1.68-.99-3.21-1.52-4.58-3.43-8.88-7.65-11.54-7.65-11.54l22.58-25.34A19.9,19.9,0,0,0,382.121,269.317Z'
            fill='#fff'
          />
          <path
            id='Path_18257'
            data-name='Path 18257'
            d='M364.181,302.557l-12.8,21.82c-6.01-10.06-9.23-23.34-9.33-23.77v-.01c2.01-4.06,7.53-8.61,7.53-8.61l1.18,14.6,5.77-15.57S360.751,293.677,364.181,302.557Z'
            fill='#ffedc5'
          />
          <path
            id='Path_18258'
            data-name='Path 18258'
            d='M340.031,355.237h15.6l.62,31.96h-23.07l-.47-31.96Zm10.72,18.08v-15.01h-12.54v15.01Z'
            fill='#fff'
          />
          <rect
            id='Rectangle_252'
            data-name='Rectangle 252'
            width='12.54'
            height='15.01'
            transform='translate(338.211 358.307)'
            fill='#826b36'
          />
          <path
            id='Path_18259'
            data-name='Path 18259'
            d='M347.211,274.2l2.37,17.79s-5.52,4.55-7.53,8.61v.01a6.75,6.75,0,0,0-.67,1.95,44.691,44.691,0,0,1-1.31-10.43C340.041,286,341.411,278.6,347.211,274.2Z'
            fill='#fff'
          />
          <path
            id='Path_18260'
            data-name='Path 18260'
            d='M344.051,350.367l3.85,4.87h-7.87Z'
            fill='#b7b7b7'
          />
          <path
            id='Path_18261'
            data-name='Path 18261'
            d='M328.521,290.477c5.95-13.75,18.93-19.57,18.93-19.57l-.24,3.29c-5.8,4.4-7.17,11.8-7.14,17.93,0,0-22.74,40.03-23.3,50.41-.21,4.03,1.54,10.58,3.77,17.08l-1.36.34-9.76-4.74.31-.63s12.42-44.02,17.42-60.42A31.586,31.586,0,0,1,328.521,290.477Z'
            fill='#cdb26f'
          />
          <path
            id='Path_18262'
            data-name='Path 18262'
            d='M328.741,379.977v13.86l-21.9,2.92-66.652-.138c-11.28,4.85-17.112,5.11-21.472,2.94-1.78.12-8.456.858-10.236.968-.17,1.92-.76,3.63-2.35,3.63-3.61,0-7.64-8.06-7.64-8.06s-1.11,8.06-4.73,6.12-2.64-12.98-2.64-12.98-8.2,8.04-9.31,5.01,4.31-17.47,17.79-18.72,43.92,4.17,43.92,4.17l60.37-10.1,22.79,5.72C327.911,378.177,328.741,379.977,328.741,379.977Z'
            fill='#ff9971'
          />
          <path
            id='Path_18263'
            data-name='Path 18263'
            d='M326.681,375.317l-22.79-5.72c-1.4-11.21,2.32-15.94,2.32-15.94l3.21,1.56,11.12,4.4h0C322.571,365.537,325,371.417,326.681,375.317Z'
            fill='#826b36'
          />
          <g id='Group_9586' data-name='Group 9586'>
            <path
              id='Path_18264'
              data-name='Path 18264'
              d='M280.806,375.2a3.357,3.357,0,0,1,.95,4.44c-7.05-.91-8.73-4.12-8.73-4.12a4.274,4.274,0,0,1,2.86-2.2A8.988,8.988,0,0,1,280.806,375.2Z'
              fill='#ed694f'
            />
            <path
              id='Path_18265'
              data-name='Path 18265'
              d='M281.756,379.64l-4.26,7.35a14.337,14.337,0,0,1-8.61-4.52l4.14-6.95S274.706,378.73,281.756,379.64Z'
              fill='#ff9076'
            />
            <path
              id='Path_18266'
              data-name='Path 18266'
              d='M277.5,386.99l-6.64,11.46-11.34-.24,9.37-15.74A14.337,14.337,0,0,0,277.5,386.99Z'
              fill='#826b36'
            />
          </g>
          <path
            id='Path_18267'
            data-name='Path 18267'
            d='M386.755,308.063l-8.233,25.927-8.2,25.808q-2.322,7.315-4.645,14.629c-.2.615.769.878.964.266q4.116-12.965,8.233-25.927l8.2-25.809q2.322-7.314,4.645-14.628C387.915,307.714,386.949,307.451,386.755,308.063Z'
            fill='#2e303d'
          />
          <path
            id='Path_18268'
            data-name='Path 18268'
            d='M420.655,358.208q-8.623,17.149-15.83,34.964-2.03,5.019-3.943,10.085c-.228.6.738.863.964.266q6.741-17.837,14.918-35.081,2.32-4.893,4.754-9.729C421.807,358.139,420.944,357.632,420.655,358.208Z'
            fill='#2e303d'
          />
          <path
            id='Path_18269'
            data-name='Path 18269'
            d='M338.709,293.287c-4.756,8.816-9.627,17.581-14.244,26.47-4.165,8.021-7.843,16.625-7.81,25.822a27.939,27.939,0,0,0,3.43,13.742.5.5,0,0,0,.863-.505c-4.316-7.6-3.927-16.864-1.44-24.994a78.037,78.037,0,0,1,5.346-12.639c2.179-4.258,4.5-8.445,6.769-12.653q3.974-7.369,7.949-14.739C339.878,293.225,339.015,292.719,338.709,293.287Z'
            fill='#2e303d'
          />
          <path
            id='Path_18270'
            data-name='Path 18270'
            d='M364.133,303.724l-16.209,36.665q-1.974,4.465-3.949,8.932a193.122,193.122,0,0,1-2.552-36.891q.148-5.5.612-10.976c.054-.641-.946-.637-1,0a193.79,193.79,0,0,0,.663,39.02q.63,5.312,1.552,10.584a.5.5,0,0,0,.057.253.384.384,0,0,0,.3.276.4.4,0,0,0,.545-.218l.011-.025a.5.5,0,0,0,.071-.16q8.063-18.24,16.126-36.479,2.316-5.238,4.631-10.476C365.254,303.646,364.393,303.136,364.133,303.724Z'
            fill='#2e303d'
          />
          <path
            id='Path_18271'
            data-name='Path 18271'
            d='M328.035,394.075l-21.266,2.085c-.635.062-.641,1.063,0,1l21.266-2.085C328.67,395.013,328.677,394.013,328.035,394.075Z'
            fill='#2e303d'
          />
          <path
            id='Path_18272'
            data-name='Path 18272'
            d='M229.151,395.932a86.12,86.12,0,0,1-18.353,3.7q-1.071.094-2.146.163a6.831,6.831,0,0,1-1.818-.014c-.456-.1-.965-.312-.934-.862.038-.683.907-1.321,1.356-1.747a54.53,54.53,0,0,1,6.578-5.328,55.653,55.653,0,0,1,8.323-4.722c.584-.269.077-1.131-.505-.864a55.968,55.968,0,0,0-15.339,10.433c-.841.806-1.942,2-1.136,3.217a3.071,3.071,0,0,0,2.759.93,78.172,78.172,0,0,0,10.107-1.156,87.015,87.015,0,0,0,11.374-2.783C230.028,396.7,229.767,395.737,229.151,395.932Z'
            fill='#2e303d'
          />
          <path
            id='Path_18273'
            data-name='Path 18273'
            d='M199,395.97a23.979,23.979,0,0,1-.048-13.539.5.5,0,0,0-.964-.266,24.968,24.968,0,0,0,.048,14.071A.5.5,0,0,0,199,395.97Z'
            fill='#2e303d'
          />
          <path
            id='Path_18274'
            data-name='Path 18274'
            d='M195.235,380.814a.512.512,0,0,0-.684.18,19.585,19.585,0,0,0-3.692,8.305c-.117.629.847.9.964.265a18.945,18.945,0,0,1,3.592-8.066A.5.5,0,0,0,195.235,380.814Z'
            fill='#2e303d'
          />
          <path
            id='Path_18275'
            data-name='Path 18275'
            d='M349.174,291.955a100.48,100.48,0,0,0-8.121,9.082c-.4.5.308,1.212.707.707a100.48,100.48,0,0,1,8.121-9.082C350.339,292.209,349.632,291.5,349.174,291.955Z'
            fill='#2e303d'
          />
          <path
            id='Path_18276'
            data-name='Path 18276'
            d='M365.856,306.109a40.493,40.493,0,0,0-8.61-15.425c-.43-.481-1.135.228-.707.707a39.339,39.339,0,0,1,8.353,14.984A.5.5,0,0,0,365.856,306.109Z'
            fill='#2e303d'
          />
          <path
            id='Path_18277'
            data-name='Path 18277'
            d='M351.559,307.42c-.028-.641-1.028-.645-1,0q.283,6.649.568,13.3c.028.641,1.028.644,1,0Q351.843,314.07,351.559,307.42Z'
            fill='#2e303d'
          />
          <path
            id='Path_18278'
            data-name='Path 18278'
            d='M355.178,309.971a1.334,1.334,0,0,0-1.614-.649,1.558,1.558,0,0,0-.48.308c-.017.015-.039.021-.054.038a1.267,1.267,0,0,0-.068.111,1.066,1.066,0,0,0-.142.172,1.147,1.147,0,0,0-.035.114c-.014.033-.02.067-.031.1a1.3,1.3,0,0,0-.071.374c0,.014-.008.027-.008.041,0,.036.016.068.019.1a1.244,1.244,0,0,0,.02.244c.008.032.031.057.042.088a1.355,1.355,0,0,0,.082.167,1.3,1.3,0,0,0,.157.251c.014.017.02.039.035.055a1.234,1.234,0,0,0,.122.09,1.289,1.289,0,0,0,.144.133c.022.014.051.015.074.027a1.394,1.394,0,0,0,.2.072,1.2,1.2,0,0,0,.248.074,1.241,1.241,0,0,0,.9-.2,1.408,1.408,0,0,0,.549-.766A1.337,1.337,0,0,0,355.178,309.971Z'
            fill='#2e303d'
          />
        </g>
      </g>
      <g id='Group_9589' data-name='Group 9589'>
        <path
          id='Path_18279'
          data-name='Path 18279'
          d='M100.131,398.487l-2.97,24.48,51.15-3.15v10.2l-74.5,8.34-20.2-15.39,23.04-54.53,8.28-19.6,20.57,5.38-2.58,21.31Z'
          fill='#8e8e8e'
        />
        <path
          id='Path_18280'
          data-name='Path 18280'
          d='M127.461,213.457l34.57,180.14H147.2l-34.75-180.14Z'
          fill='#8e8e8e'
        />
        <path
          id='Path_18281'
          data-name='Path 18281'
          d='M148.311,419.817l-51.15,3.15,2.97-24.48Z'
          fill='#b7b7b7'
        />
        <path
          id='Path_18282'
          data-name='Path 18282'
          d='M112.451,213.457,147.2,393.6l-44.28-18.07,2.58-21.31-20.57-5.38-8.28,19.6-3.67-1.56-40.04-153.42Z'
          fill='#b7b7b7'
        />
        <path
          id='Path_18283'
          data-name='Path 18283'
          d='M139.9,353.569q-2.876-16.917-5.9-33.809-3.042-16.976-6.237-33.923-3.179-16.863-6.507-33.7T114.449,218.5q-.435-2.1-.872-4.2c-.132-.63-1.1-.363-.965.266q3.5,16.8,6.846,33.63T126,281.888q3.213,16.944,6.275,33.916,3.045,16.887,5.941,33.8t5.639,33.853q.342,2.118.683,4.235c.1.634,1.066.365.964-.265Q142.78,370.487,139.9,353.569Z'
          fill='#2e303d'
        />
        <path
          id='Path_18284'
          data-name='Path 18284'
          d='M99.325,353.789q-4.563,16.266-9.495,32.426-4.914,16.1-10.2,32.084-2.988,9.04-6.091,18.043c-.21.61.755.872.964.266q5.5-15.972,10.647-32.066,5.121-16.035,9.875-32.184,2.689-9.135,5.26-18.3A.5.5,0,0,0,99.325,353.789Z'
          fill='#2e303d'
        />
      </g>
    </g>
  </svg>
)
