import { Drawer, Grid } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { useNavigate } from 'react-router-dom'
import { SidebarContainer } from './components/SidebarContainer'
import './styles.scss'
const DashboardSidebar = ({ open, onDrawerClose }) => {
  const { useBreakpoint } = Grid
  const { lg, xl } = useBreakpoint() ?? {}
  const navigate = useNavigate()

  const handleSidebarClick = (pagePath) => {
    navigate(`${pagePath}`, { replace: true })
    !lg && onDrawerClose();
  }

  if (lg === undefined) return null
  return (
    <>
      <Sider width={xl ? 270 : lg ? 230 : 0} className={'dashboard-sidebar'}>
        <SidebarContainer handleSidebarClick={handleSidebarClick} />
      </Sider>
      <Drawer
        open={open}
        placement={'left'}
        closable={false}
        onClose={onDrawerClose}
        destroyOnClose
        width={'90%'}
        className={'dashboard-sidebar'}
        rootClassName={'dashboard-drawer-sidebar'}
        style={{
          maxWidth: 270,
        }}
      >
        <SidebarContainer handleSidebarClick={handleSidebarClick} />
      </Drawer>
    </>
  )
}

export default DashboardSidebar
