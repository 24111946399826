import { Typography } from 'antd'
import { PLAY } from 'assets'
import { PrimaryButton } from 'globalComponents'
import { sidebarList } from '../config/constants'
import { SidebarListItem } from './ListItem'

export const SidebarContainer = ({ handleSidebarClick }) => {
  const params = window.location.pathname
  return (
    <div className={'sidebar-container'}>
      <div>
        <div className={'go-live-wrapper'}>
          <div className={'left'}>
            <img src={PLAY} alt={'play'} />
            <Typography.Paragraph className={'go-live-text -mb-3-imp'}>Live</Typography.Paragraph>
          </div>
          <div className='right -mb-3-imp'>
            <p>Join {' >'}</p>
          </div>
        </div>
        <div className={'d-sidebar-list-items'}>
          {sidebarList.map(({ text, image, path }) => (
            <SidebarListItem
              key={text}
              image={image}
              text={text}
              path={path}
              selected={params === path | (path !=='/' && params.includes(path))}
              onClick={handleSidebarClick}
            />
          ))}
        </div>
      </div>
      <div className='ds-setting-container'>
        <PrimaryButton text={'Settings'} block />
      </div>
    </div>
  )
}
