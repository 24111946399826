import { loginApiAxiosParams } from "api/useAxiosParams";
import { LOCAL_STORAGE_VALUES } from "constants/common";
import useUser from "context/user/useUser";
import { useAxios } from "hooks";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const { callAxios: callLoginUserApi, loading } =
    useAxios(loginApiAxiosParams);

  const { onLoginUser } = useUser();

  const handleNavigation = useCallback(
    (path) => () => {
      navigate(path);
    },
    [navigate]
  );
  const handleLoginUser = useCallback(
    async (data) => {
      const result = await callLoginUserApi({
        data,
      });

      console.log(data);
      if (result?.success) {
        console.log({ result });
        // localStorage.setItem('user', TOKEN, result?.data?.token);
        onLoginUser(result?.data?.user);
      }
    },
    [callLoginUserApi, onLoginUser]
  );

  const handleLoginFormSubmit = handleSubmit(handleLoginUser);

  return { handleNavigation, handleLoginFormSubmit, register, errors, loading };
};

export default useLogin;
