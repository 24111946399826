import { Col, Row, Typography } from "antd";
import { projectCard } from "assets";

const MyProjects = () => {
  return (
    <div className="projects-wrapper">
      <Row gutter={[10, 10]}>
        <Col xs={12} md={24} lg={12}>
          <ProjectCard />
        </Col>

        <Col xs={12} md={24} lg={12}>
          <ProjectCard />
        </Col>
        <Col xs={12} md={24} lg={12}>
          <ProjectCard />
        </Col>
        <Col xs={12} md={24} lg={12}>
          <ProjectCard />
        </Col>
      </Row>
    </div>
  );
};

const ProjectCard = () => (
  <div className="project-card">
    <img src={projectCard} alt="" />
    <div className="right">
      <Typography.Text className="font-bold">Funeral</Typography.Text>
      <Typography.Text className="text-small">In Progress</Typography.Text>
    </div>
  </div>
);

export default MyProjects;
