import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import "./verify.scss"
const VerifyPage = () => {

  return (
    <div className=" verify-area">
      <Row>
        <Col className="flex flex-col gutter-row left-content " span={12}>
          <div className="relative flex flex-col items-start justify-between w-full h-full p-10">
              <div className={"left-content-2"}>
                  <div className="relative z-10 w-full top-content">
                      <h1>Verify Email</h1>
                      <p>Please Check your email address and verify your email</p>
                      <div className="flex flex-col items-center justify-center p-5 verify-box">
                          <img src="/img/email.png" alt=""/>
                          <h2>elizabeth@bennetcom</h2>
                          <p>You've entered as the email address for your account.Please verify this email address by
                              clicking button below.</p>
                          <div className="divider"></div>
                          <NavLink to="">Change Mail address?</NavLink>
                      </div>
                      <button type="button" className="mt-5 go-next">
                          Verify Your email
                      </button>
                  </div>
              </div>
              <div className="relative z-10 w-full bottom-content">
                  <div className="flex items-center divider-list">
                      <div style={{marginBottom: '20px'}} className="divider"></div>
                      {/*<p >Or</p>*/}
                  </div>
                  <div className="text-center text-white signup-text">
                      <p>If you did not sign up for this account you can ignore
                          this email and the account will be deleted</p>
                  </div>
              </div>
              <div className="absolute bottom-0 left-0 overly-effect ">
                  <img src="/img/login/effect.png" alt="effect"/>
              </div>

          </div>
        </Col>
          <Col
              className="flex flex-col items-center justify-center h-full gutter-row"
          span={12}
        >
          <div className="relative flex items-center justify-center w-full right-content">
            <img
              src="/img/VerifyEmail.png"
              alt="effect2"
              className="effect"
            />
            <div className="absolute top-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full overly-box">
              <img src="/img/login/logo.png" alt="" />
              <h2>Corsa University</h2>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyPage;
