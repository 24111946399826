import { Col, Grid, Modal, Row } from "antd";
import { CustomCard } from "globalComponents";
import { useState } from "react";
import { getServerUrlAttached } from "utils";
import { ServiceModalContent } from "./ServiceModalContent";

const { useBreakpoint } = Grid;
export const ServicesList = ({ services }) => {
  const { sm, md } = useBreakpoint();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const column = (val, index) => (
    <Col key={index} xs={12} sm={8} md={8} lg={8} xl={6} xxl={6}>
      <CustomCard
        primaryText={val?.name}
        secondaryText={`$${val.fromPrice} - $${val.toPrice}`}
        imgSrc={val.thumbnail}
        arrowText={"Get a Quote"}
        onClick={toggleModal}
      />
    </Col>
  );

  return (
    <div className={"cards-list-container"}>
      <Row gutter={[10, 20]} className="w-100">
        {services.map(column)}
      </Row>
      <Modal
        open={showModal}
        onCancel={toggleModal}
        centered
        closeIcon={<div />}
        wrapClassName={"service-modal"}
        footer={false}
        width={md ? 760 : sm ? 400 : 300}
      >
        <ServiceModalContent />
      </Modal>
      {showModal && (
        <div className="blurred-background" onClick={toggleModal} />
      )}
    </div>
  );
};
