import { Divider, Typography } from "antd";
import { AccountTabs } from "./components";
import "./styles.scss";

const AccountPage = () => {
  return (
    <div className="account-page-wrapper page-container">
      <div className="title-wrapper">
        <Typography.Title level={4} className="page-title">
          My Account
        </Typography.Title>
      </div>
      <div className="account-page-content">
        <div className="ac-top">
          <Typography.Text className="logout-btn font-bold">
            Logout
          </Typography.Text>
        </div>
        <Divider />
        <AccountTabs />
      </div>
    </div>
  );
};

export default AccountPage;
