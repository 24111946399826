import AXIOS_METHODS from "./axiosMethods";
import API_ENDPOINTS from "./apiEndpoints";

export const loginApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.LOGIN,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const registerApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.REGISTER,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const meApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.TOKEN_VALIDITY,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const getCoursesApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_COURSES,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const getCoursesByInstructorIdApiAxiosParams = (instructorId) => ({
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_COURSES_BY_INSTRUCTOR_ID(instructorId),
  },
  shouldCallOnMount: true,
  showMessage: false,
});

export const deleteUserApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.PUT,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getCourseDetailApiAxiosParams = (courseId) => ({
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_COURSE_DETAIL(courseId),
  },
  shouldCallOnMount: true,
  showMessage: false,
});

export const addCourseApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.ADD_COURSE,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const addInstructorApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.ADD_INSTRUCTOR,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getInstructorsApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_INSTRUCTORS,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const getServicesApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_SERVICES,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const addServiceApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.ADD_SERVICE,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getInstructorDetailsAxiosParams = (instructorId) => ({
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_INSTRUCTOR_DETAIL(instructorId),
  },
  shouldCallOnMount: true,
});

export const getInstructorScheduleAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
  },
  shouldCallOnMount: false,
  showMessage: false,
};

export const bookSlotAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.BOOK_SLOT,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getAdminDashboardAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_ADMIN_DASHBOARD,
  },
  shouldCallOnMount: true,
  showMessage: false,
};
