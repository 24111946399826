import { Grid, Layout } from "antd";
import { Outlet } from "react-router-dom";
import { Chats, DashboardHeader, DashboardSidebar } from "./components";
import { useEffect, useState } from "react";

const { Content } = Layout;

const DefaultLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleDrawer = () => setShowSidebar(!showSidebar);

  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint() ?? {};
  useEffect(() => {
    if (lg) setShowSidebar(false);
  }, [lg]);

  return (
    <Layout className="default-layout-wrapper">
      <DashboardHeader openDrawer={toggleDrawer} />
      <Layout className={"body-container"}>
        <DashboardSidebar open={showSidebar} onDrawerClose={toggleDrawer} />
        <Layout className="content-layout">
          <Content className="content-wrapper">
            <div className={"content-auto"}>
              <Outlet />
            </div>
          </Content>
          <Chats />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
