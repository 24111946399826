import { Typography } from 'antd'
import './styles.scss';

const NotFoundPage = () => {
  return (
      <div className='nfp-wrapper'>
        <div className={'nfp-title'}>
          4
          <span role='img' aria-label='Crying Face'>
            😢
          </span>
          4
        </div>
        <Typography.Title style={{color:'black'}} level={3}>{'Page not found.'}</Typography.Title>
      </div>
  )
}

export default NotFoundPage;