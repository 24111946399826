import { Button } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { rightArrowSvg } from 'assets'

export const ChatInput = () => {
  return (
    <div className={'ci-container'}>
      <div className={'ci-input-wrapper'}>
        <TextArea
          className={'ci-input'}
          // value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder={'Enter a text'}
          autoSize={{ minRows: 1, maxRows: 2 }}
        />
      </div>
      <Button className={'ci-send-button'}>{rightArrowSvg}</Button>
    </div>
  )
}
