import { Col, Row } from "antd";
import { appLogoHuge } from "assets";
import { Outlet } from "react-router-dom";
import { AuthHeader } from "./components";
import './authLayout.scss'
const AuthLayout = () => {
  return (
        <main className="">
            <Outlet />
        </main>
  );
};

export default AuthLayout;
