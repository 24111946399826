import { registerApiAxiosParams } from "api/useAxiosParams";
import { LOCAL_STORAGE_VALUES } from "constants/common";
import useUser from "context/user/useUser";
import { useAxios } from "hooks";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

const useRegister = () => {
  const { callAxios: callRegisterUserApi, loading } = useAxios(
    registerApiAxiosParams
  );

  const { onLoginUser } = useUser();


  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange" });

  const registerUser = useCallback(
    async (data) => {
      const { confirmPassword, ...payload } = data;

      const result = await callRegisterUserApi({
        data: payload,
      });

      if (result?.success) {
        localStorage.setItem(LOCAL_STORAGE_VALUES.TOKEN, result?.data?.token);
        onLoginUser(result?.data?.user);
      }
    },
    [callRegisterUserApi, onLoginUser]
  );

  const handleRegisterUserFormSubmit = handleSubmit(registerUser);

  return { register, errors, handleRegisterUserFormSubmit, watch, loading };
};

export default useRegister;
