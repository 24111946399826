import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from "antd";
import { arrowRightWhite } from "assets";
import { CustomModal, ImagePicker } from "globalComponents";
import { formRules } from "./formRules";
import useManageServiceModal from "./useManageServiceModal";

const ManageServiceModal = ({ open, onCancel }) => {
  const { handleAddService, loading, form } = useManageServiceModal({
    onCancel,
  });

  return (
    <CustomModal open={open} onCancel={onCancel}>
      <Form
        layout="vertical"
        className="form-style-1"
        onFinish={handleAddService}
        form={form}
      >
        <Row className="h-100" gutter={[10, 20]}>
          <Col xs={24} sm={11}>
            <ImagePicker
              title={"Add Thumbnail"}
              description={"1204 x 1204"}
              name={"thumbnail"}
              rules={formRules.thumbnail}
            />
          </Col>
          <Col className="h-100" xs={24} sm={13}>
            <Space>
              <Typography.Title className="font-bold" level={4}>
                Add A New Service
              </Typography.Title>
            </Space>

            <Form.Item label="Name" name={"name"} rules={formRules.name}>
              <Input className="input-style-1" />
            </Form.Item>
            <Form.Item
              label="Description"
              name={"description"}
              rules={formRules.description}
            >
              <Input className="input-style-1" />
            </Form.Item>
            <Space direction="vertical" className="w-100" size={10}>
              <label>Price</label>
              <Space className="w-100">
                <Form.Item
                  className="form-item-direction-row"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 16 }}
                  label="From"
                  name={"fromPrice"}
                  rules={formRules.fromPrice}
                >
                  <InputNumber
                    controls={false}
                    className="input-style-1 w-100"
                  />
                </Form.Item>
                <Form.Item
                  className="form-item-direction-row"
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 19 }}
                  label="To"
                  name={"toPrice"}
                  rules={formRules.toPrice}
                >
                  <InputNumber
                    controls={false}
                    className="input-style-1 w-100"
                  />
                </Form.Item>
              </Space>
            </Space>
            <Button
              type="primary"
              className="btn-modal-variant with-icon"
              block
              htmlType="submit"
              loading={loading}
              icon={<img alt="arrow-right" src={arrowRightWhite} />}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default ManageServiceModal;
