import { Typography } from 'antd'
import React from 'react'
import './styles.scss'
import { FAQList } from './components/FaqList'
const FAQPage = () => {
  return (
    <div className={'faq-page-container'}>
      <div className={'faq-title-wrapper'}>
        <Typography.Title className={'faq-title'} level={3}>
          {'Frequently Asked Questions'}
        </Typography.Title>
      </div>
      <FAQList />
    </div>
  )
}

export default FAQPage
