// formRules.js
export const formRules = {
  modules: {
    name: [
      {
        required: true,
        message: "Please input the module name!",
        whitespace: true,
      },
    ],
    description: [
      {
        required: true,
        message: "Please input the module description!",
        whitespace: true,
      },
    ],
    lessons: {
      lesson: [
        {
          validator: (_, value) =>
            Array.isArray(value) && value.length
              ? Promise.resolve()
              : Promise.reject(new Error("Please upload atleast one lesson!")),
        },
      ],

      name: [
        {
          required: true,
          message: "Please input the lesson name!",
          whitespace: true,
        },
      ],
      lessonFile: [
        {
          required: true,
          message: "Please upload a file for the lesson!",
        },
      ],
    },
  },
};
