export const formRules = {
  name: [
    {
      required: true,
      message: "Name is required",
    },
  ],
  description: [
    {
      required: true,
      message: "Description is required",
    },
  ],
  instructors_id: [{ required: true, message: "Please select an instructor" }],
  price: [
    {
      required: true,
      message: "Price is required",
    },
  ],
  thumbnail: [
    {
      required: true,
      message: "Thumbnail is required",
    },
  ],
};
