import useMessage from "antd/es/message/useMessage";
import API_ENDPOINTS from "api/apiEndpoints";
import { deleteUserApiAxiosParams } from "api/useAxiosParams";
import { getInstructorsApiAxiosParams } from "api/useAxiosParams";
import { useAxios } from "hooks";
import { useEffect, useState } from "react";

const useMentorship = () => {
  const [messageApi, messageContextHolder] = useMessage();

  const [isManageMentorModalVisible, setisManageMentorModalVisible] =
    useState(false);

  const {
    result: instructorsApiResult,
    loading: getInstructorsApiLoading,
    callAxios: callGetInstructorsApi,
  } = useAxios(getInstructorsApiAxiosParams);

  const { loading: deleteUserApiLoading, callAxios: callDeleteUserApi } =
    useAxios(deleteUserApiAxiosParams);

  const toggleManageMentorModalVisibility = async ({ success = false }) => {
    setisManageMentorModalVisible(!isManageMentorModalVisible);
    if (success) {
      await callGetInstructorsApi();
    }
  };

  const handleInstructorDelete = async (user) => {
    try {
      const response = await callDeleteUserApi({
        url: API_ENDPOINTS.DELETE_USER(user.id),
      });
      if (response?.success) {
        await callGetInstructorsApi();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (deleteUserApiLoading) messageApi.loading("deletion in progress", 0);
    else if (deleteUserApiLoading === false) messageApi.destroy();
    return () => messageApi.destroy();
  }, [deleteUserApiLoading, messageApi]);

  return {
    messageContextHolder,
    instructors: instructorsApiResult?.data || [],
    toggleManageMentorModalVisibility,
    isManageMentorModalVisible,
    getInstructorsApiLoading,
    handleInstructorDelete,
  };
};

export default useMentorship;
