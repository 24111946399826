import { Loader } from "globalComponents";
import { CourseDetailTitle } from "./components/CourseDetailTitle";
import { CourseDetailVideo } from "./components/CourseDetailVideo";
import { CourseInformation } from "./components/CourseInformation";
import "./styles.scss";
import useCourseDetail from "./useCourseDetail";
import {useEffect, useState} from "react";
import userProvider from "../../../context/user/userProvider";
import NetworkingAPI from "../../../context/NetworkingAPI";
import {useParams} from "react-router-dom";
const CourseDetailsPage = () => {

  const [courseDetail, setCourseDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);

  const params = useParams();
    async function initializePage() {
        setLoading(true);
        const currentUser = await userProvider.getCurrentUser();

        if (!currentUser) {
            return console.log("Please enter a valid email address");
        }
        try {
            const response = await NetworkingAPI.fetchData(`course/get-course/${params.courseId}`, 'GET', undefined);
            const data = response.data;
            setCourseDetail(data.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        initializePage()
    },[]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={"course-details-page-container"}>
      {selectedMediaFile && <CourseDetailVideo imageUrl={selectedMediaFile} />}
      <CourseDetailTitle courseDetail={courseDetail} />
      <CourseInformation
        courseDetail={courseDetail}
        setSelectedMediaFile={setSelectedMediaFile}
      />
    </div>
  );
};

export default CourseDetailsPage;
