import React from 'react'
import { ChatListContainer } from './ChatListContainer'
import { ChatInput } from './ChatInput'

export const ChatContainer = ({ showChat, locked }) => {
  return (
    <div className='c-chat-container'>
      <ChatListContainer showChat={showChat} locked={locked} />
      <ChatInput />
    </div>
  )
}
