import { Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { forwardRef, useImperativeHandle } from "react";
import { urlPattern } from "utils";

const MentorSocialLinksForm = (_, ref) => {
  const [form] = useForm();

  const urlValidationRule = {
    pattern: urlPattern,
    message: "Please enter a valid URL with https://www and .com",
  };

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit: () => form.validateFields(),
    }),
    [form]
  );
  return (
    <Form layout="vertical" className="form-style-1" form={form}>
      <Typography.Title className="font-bold" level={4}>
        Add Social Links
      </Typography.Title>
      <Form.Item
        label="Instagram"
        name="instagramURL"
        rules={[urlValidationRule]}
      >
        <Input className="input-style-1" />
      </Form.Item>
      <Form.Item
        label="Facebook"
        name="facebookURL"
        rules={[urlValidationRule]}
      >
        <Input className="input-style-1" />
      </Form.Item>
      <Form.Item
        label="LinkedIn"
        name="linkedInURL"
        rules={[urlValidationRule]}
      >
        <Input className="input-style-1" />
      </Form.Item>
      <Form.Item label="Twitter" name="twitterURL" rules={[urlValidationRule]}>
        <Input className="input-style-1" />
      </Form.Item>
    </Form>
  );
};

export default forwardRef(MentorSocialLinksForm);
