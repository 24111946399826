import { CheckCircleFilled } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import { mentorDetailImage } from "assets";
import { PrimaryButton } from "globalComponents";
import { getServerUrlAttached } from "utils";
import "./styles.scss";
import useMentorshipDetails from "./useMentorshipDetails";
import { Loader } from "globalComponents";
import {useEffect, useState} from "react";
import userProvider from "../../../context/user/userProvider";
import NetworkingAPI from "../../../context/NetworkingAPI";
import {useParams} from "react-router-dom";

const MentorshipDetailsPage = () => {

  const params = useParams();

  const [instructorDetail, setInstructorDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  function onLinkClick(e) {

  }

  function onNavigation(e) {

  }

  async function initializePage() {
    const currentUser = await userProvider.getCurrentUser();

    if (!currentUser) {
      return console.log("Please enter a valid email address");
    }
    try {
      const response = await NetworkingAPI.fetchData(`course/get-educator/${params.userId}`, 'GET', undefined);
      const data = response.data;
      setInstructorDetail(data.data)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    initializePage()
  },[])

  if (loading) {
    return <Loader />;
  } else if (Object.keys(instructorDetail).length)
    return (
      <div className={"mentorship-details-page-container"}>
        <Row className={"mentorship-details-container"}>
          <Col xs={24} xl={9}>
            <div className={"mdp-image-container"}>
              <img
                className={"mdp-image"}
                src={instructorDetail?.photo}
                alt={"mentor"}
              />
            </div>
          </Col>
          <Col xs={24} xl={15}>
            <MentorDetails
              onNavigation={onNavigation}
              links={links}
              instructorDetail={instructorDetail}
              onLinkClick={onLinkClick}
            />
          </Col>
        </Row>
        <Typography.Title className={"mdp-latest-title"} level={4}>
          {"Latest on IG"}
        </Typography.Title>
        <MentorshipPosts />
      </div>
    );
};

const MentorDetails = ({
  instructorDetail,
  onLinkClick,
  links,
  onNavigation,
}) => (
  <div className={"mdp-details"}>
    <div>
      <Space direction="vertical" size={15}>
        <div className={"mdp-title-container"}>
          <Typography.Title level={2} className={"mdp-title"}>
            {instructorDetail?.name}
          </Typography.Title>
          <CheckCircleFilled
            className={`mdp-icon ${instructorDetail?.verification}`}
          />
        </div>
        <Typography.Title className={"mdp-about-title"} level={5}>
          About {instructorDetail?.name}
        </Typography.Title>
        <Typography.Text className={"mdp-about-description"}>
          {instructorDetail?.description}
        </Typography.Text>
      </Space>
    </div>
    <div>
      <div className="df-row mdp-button-container">
        <PrimaryButton
          text={"View Courses"}
          onClick={onNavigation(
            `/courses/${instructorDetail?.instructors?.name}/${instructorDetail?.instructors?.id}`
          )}
        />
        <PrimaryButton
          text={"Book a call"}
          style={{ background: "white" }}
          onClick={onNavigation(
            `/educator/detail/${instructorDetail?._id}`
          )}
        />
      </div>
      <div className="df-row mdp-social-container">
        <Typography.Paragraph className={"mdp-social-text"}>
          {"Find me on"}
        </Typography.Paragraph>
        {links.map((link) => (
          <div className={"svg-wrapper"} onClick={onLinkClick(link?.url)}>
            {link?.icon}
          </div>
        ))}
      </div>
    </div>
  </div>
);

const MentorshipPosts = () => {
  const mentorshipPost = (val, index) => (
    <Col key={index} xs={24} sm={12} xl={8}>
      <div className={"mdp-post-container"}>
        <img src={mentorDetailImage} alt={"mentor"} />
      </div>
    </Col>
  );
  return (
    <div className={"mdp-post-list-container"}>
      <Row gutter={{ xs: 0, sx: 10 }}>{[...Array(13)].map(mentorshipPost)}</Row>
    </div>
  );
};

export default MentorshipDetailsPage;
