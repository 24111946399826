import { forwardRef } from "react";
import "./styles.scss";
//
// const CustomInput = forwardRef(
//   ({ label = "", icon = null, error, onChange, ...rest }, ref) => {
//     return (
//       <div className={`custom-input-wrapper ${error ? "error" : ""}`}>
//         <label>{label}</label>
//         <input onChange={onChange} ref={ref} />
//         {error && <span className="form-error-message">{error?.message}</span>}
//       </div>
//     );
//   }
// );

const CustomInput = ({onChange, label, placeholder, showPassword, type}) => {
    let error;

    return(
        <>
            <div className={`custom-input-wrapper ${error ? "error" : ""}`}>
                <label>{label ?? ''}</label>
                <input type={type === 'password' && !showPassword ? 'password' : ''} placeholder={placeholder} onChange={onChange}/>
                {error && (<span className="form-error-message">{error?.message}</span>)}
            </div>
        </>
    )
};

export default CustomInput;
