import { mentorshipImage } from "assets";
import { sidebarServicesImage } from "assets";
import { dashboardImage } from "assets";
import { affiliateImage } from "assets";
import { faqImage } from "assets";
import { educatorImage } from "assets";
import { coursesImage } from "assets";

export const sidebarList = [
  {
    image: dashboardImage,
    text: "Dashboard",
    path: "/",
  },
  {
    image: coursesImage,
    text: "Courses",
    path: "/courses",
  },
  {
    image: educatorImage,
    text: "Speak w/ an Educator",
    path: "/educator",
  },
  {
    image: mentorshipImage,
    text: "Mentorship",
    path: "/mentorship",
  },
  {
    image: mentorshipImage,
    text: "Automations",
    path: "/automations",
  },
  {
    image: sidebarServicesImage,
    text: "Services",
    path: "/services",
  },
  {
    image: affiliateImage,
    text: "Affiliate",
    path: "/affiliate",
  },
  {
    image: faqImage,
    text: "FAQs",
    path: "/faqs",
  },
  {
    image: "",
    text: "Admin",
    path: "/admin",
  },
];
