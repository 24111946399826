import { Button, Typography } from "antd";
import { Loader } from "globalComponents";
import ManageServiceModal from "./components/ManageServiceModal";
import { ServicesList } from "./components/ServicesList";
import "./styles.scss";
import useServices from "./useServices";
import {useEffect, useState} from "react";
import userProvider from "../../../context/user/userProvider";
import NetworkingAPI from "../../../context/NetworkingAPI";

const ServicesPage = () => {

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isManageServiceModalVisible, setIsManageServiceModalVisible] = useState(false);

  function toggleManageServiceModalVisibility() {
      setIsManageServiceModalVisible(!isManageServiceModalVisible)
  }

  async function initializePage() {
      const currentUser = await userProvider.getCurrentUser();

      if (!currentUser) {
          return console.log("Please enter a valid email address");
      }
      try {
          const response = await NetworkingAPI.fetchData(`service/get-services`, 'GET', undefined);
          const data = response.data;
          setServices(data.data)
      } catch (e) {
          console.log(e);
      }
  }

  useEffect(() => {
      initializePage()
  },[]);

  return (
    <div className={"services-page-container cards-main-container"}>
      <div className={"cards-title"}>
        <Typography.Title className={"title"} level={4}>
          {"Our Services"}
        </Typography.Title>
        <Button type="primary" onClick={toggleManageServiceModalVisibility}>
          Add
        </Button>
      </div>
      <ManageServiceModal
        open={isManageServiceModalVisible}
        onCancel={toggleManageServiceModalVisibility}
      />

      {loading ? (
        <Loader />
      ) : (
        <ServicesList services={services} />
      )}
    </div>
  );
};

export default ServicesPage;
