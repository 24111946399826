import { Tooltip, Typography } from 'antd'
import { checkCurlyPlatinumImage } from 'assets'
import React from 'react'

const progress = 35

export const AffiliateGoals = () => {
  return (
    <div className={'ag-goal-container'}>
      <div className={'info-container'}>
        <Typography.Title className={'highlighted'} level={3}>
          {'Your Next Goal'}
        </Typography.Title>
        <div className={'info-right'}>
          <Typography.Title className={'ar-text'} level={3}>{'Active Referrals'}</Typography.Title>
          <Tooltip title={'Active Referrals'}>
            <Typography.Title className={'highlighted'} level={3}>
              {'7/10'}
            </Typography.Title>
          </Tooltip>

          <div className={'level-container'}>
            <Tooltip title={'Platinum'}>
              <img src={checkCurlyPlatinumImage} alt={'check'} />
            </Tooltip>
            <Typography.Title className={'highlighted platinum'} level={3}>
              {'Platinum'}
            </Typography.Title>
          </div>
        </div>
      </div>
      <div className={'goal-progress-track'}>
        <div className='goal-progress-bar' style={{ width: `${progress}%` }} />
      </div>
    </div>
  )
}
