import { Col, List, Row, Typography } from "antd";
import useAccountTabs from "./useAccountTabs";
import "./styles.scss";

const AccountTabs = () => {
  const { activeTabIndex, onTabClick, tabs } = useAccountTabs();

  return (
    <Row gutter={[30, 30]}>
      <Col xs={24} md={8}>
        <List
          itemLayout="vertical"
          dataSource={tabs}
          renderItem={(item, index) => (
            <List.Item
              className={`account-tab ${
                activeTabIndex === index ? "active" : ""
              }`}
              onClick={onTabClick(index)}
            >
              <div className="at-icon" />
              <Typography.Text className="at-title m-0 font-semibold">
                {item?.title}
              </Typography.Text>
            </List.Item>
          )}
        />
      </Col>
      <Col xs={24} md={16}>
        {tabs[activeTabIndex]?.component}
      </Col>
    </Row>
  );
};

export default AccountTabs;
