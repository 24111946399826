import { useAxios } from "hooks";

import { useForm } from "antd/es/form/Form";
import { addServiceApiAxiosParams } from "api/useAxiosParams";
import { useCallback } from "react";

const useManageServiceModal = ({ onCancel }) => {
  const [form] = useForm();

  const { loading, callAxios: callAddServiceApi } = useAxios(
    addServiceApiAxiosParams
  );

  const handleAddService = useCallback(
    async (values) => {
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        formData.append(
          "thumbnail",
          values?.thumbnail?.fileList[0].originFileObj
        );

        const result = await callAddServiceApi({
          data: formData,
        });
        if (result?.success) {
          form.resetFields();
          onCancel({ success: true });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [callAddServiceApi, form, onCancel]
  );

  return { handleAddService, loading, form };
};

export default useManageServiceModal;
