import { Col, Row, Typography } from "antd";
import { Loader } from "globalComponents";
import { AdminListingCard } from "./components";
import useAdmin from "./useAdmin";
import ManageMentorModal from "../Mentorship/components/ManageMentorModal";
import ManageCourseModal from "../Courses/components/ManageCourseModal";

const AdminPage = () => {
  const {
    handleViewClick,
    educatorAddNewTitleBtnProps,
    coursesAddNewTitleBtnProps,
    mentorshipAddNewTitleBtnProps,
    adminDashboardApiLoading,
    listings,
    toggleManageModalVisibility,
    isManageCourseModalVisible,
    setIsManageCourseModalVisible,
    setisManageMentorModalVisible,
    isManageMentorModalVisible,
  } = useAdmin();

  return (
    <>
      <ManageMentorModal
        open={isManageMentorModalVisible}
        onCancel={toggleManageModalVisibility(
          setisManageMentorModalVisible,
          isManageMentorModalVisible
        )}
      />
      <ManageCourseModal
        open={isManageCourseModalVisible}
        onCancel={toggleManageModalVisibility(
          setIsManageCourseModalVisible,
          isManageCourseModalVisible
        )}
      />
      <div className="admin-page-container page-container">
        <div className="title-wrapper">
          <Typography.Title level={4} className="page-title">
            Admin Dashboard
          </Typography.Title>
        </div>
        <div className="admin-page-content">
          {adminDashboardApiLoading ? (
            <Loader />
          ) : (
            <Row gutter={[20, 20]}>
              <Col xs={24} md={12}>
                <AdminListingCard
                  title="Educators"
                  handleViewClick={handleViewClick}
                  addNewTitleBtnProps={educatorAddNewTitleBtnProps}
                  listing={listings.instructors}
                  actionTitle={"View Educators"}
                />
              </Col>
              <Col xs={24} md={12}>
                <AdminListingCard
                  title="Courses"
                  handleViewClick={handleViewClick}
                  addNewTitleBtnProps={coursesAddNewTitleBtnProps}
                  listing={listings.courses}
                  actionTitle={"View Courses"}
                />
              </Col>
              <Col xs={24} md={12}>
                <AdminListingCard
                  title="Mentorships"
                  handleViewClick={handleViewClick}
                  addNewTitleBtnProps={mentorshipAddNewTitleBtnProps}
                  listing={listings.mentors}
                  actionTitle={"View Mentors"}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminPage;
