const environment = process.env.REACT_APP_ENVIRONMENT;
export const APIBaseURL = environment === "development" ? "http://localhost:4200/api/" : "https://corsauniversity-api-ffcfe797858d.herokuapp.com/api/";

const NetworkingAPI = {

    async fetchData(url: String, method: 'GET' | 'POST' | 'DELETE', requestBody) {

        let formattedURL = APIBaseURL + url

        try {

            const options: RequestInit = {
                method: method,
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                }
            };

            if (requestBody) {
                options.body = JSON.stringify(requestBody);
            }

            const response = await fetch(formattedURL, options);

            const data = await response.json();

            if (response.ok) {
                return {data: data};
            } else {
                console.log('Failed to fetch data:', response.statusText, data.error.message);
                return {error: data.error.message} ?? response.statusText;
            }
        } catch (error) {
            console.log('Error fetching data:', error);

        }
    }

};

export default NetworkingAPI;