import { Typography } from 'antd'

export const MapDetailItem = () => {
  return (
    <div className={'detail-item-container'}>
      <div className={'detail-item-wrapper'}>
        <Typography.Paragraph className={'mdi-primary-text'}>{'254'}</Typography.Paragraph>
        <Typography.Paragraph className={'mdi-secondary-text'}>{'Africa'}</Typography.Paragraph>
      </div>
    </div>
  )
}
