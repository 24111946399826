import React from 'react'
import { AffiliateHeader } from './components/AffiliateHeader'
import './styles.scss'
import { AffiliateCopy } from './components/AffiliateCopy'
import { AffiliateDashboardCards } from './components/AffiliateDashboardCards'
import { AffiliateGoals } from './components/AffiliateGoals'
import { useState } from 'react'
import { AffiliateGetStarted } from './components/AffiliateGetStarted'
import { AffiliateSubscriptions } from './components/AffiliateSubscriptions'

const AffiliatePage = () => {
  const [isNewUser, setIsNewUser] = useState(true)

    if (isNewUser) {
        return (
            <div className={'affiliate-page-container'}>
                <LandingAffiliatePage updateUserStatus={setIsNewUser}/>
            </div>
        )
    }

    if (!isNewUser) {
        return (
            <div className={'affiliate-page-container-2'}>
                <MainAffiliatePage/>
            </div>
        )
    }
}

const LandingAffiliatePage = ({ updateUserStatus }) => (
  <>
    <AffiliateGetStarted updateUserStatus={updateUserStatus} />
    <AffiliateSubscriptions />
  </>
)

const MainAffiliatePage = () => (
  <>
    <AffiliateHeader />
    <AffiliateCopy />
    <AffiliateDashboardCards />
    <AffiliateGoals />
  </>
)

export default AffiliatePage
