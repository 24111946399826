import { meApiAxiosParams } from "api/useAxiosParams";
import { useAxios } from "hooks";
import { createContext, useCallback, useEffect, useState } from "react";

// Create a new context for layout management
export const UserContext = createContext();

// Create a provider component to wrap around the app
export const UserProvider = ({ children }) => {
  // Use state to manage active heading and logged in user info

  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { result: meApiResult, error } = useAxios(meApiAxiosParams);

  const onLoginUser = useCallback((user) => {
    setIsAuthenticated(true);
    setUser(user);
  }, []);
  const onLogoutUser = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (meApiResult?.success) {
      setUser(meApiResult?.data?.user);
      setIsAuthenticated(true);
    } else if (error?.response?.status === 403) {
      setIsAuthenticated(false);
    }
  }, [meApiResult, error]);

  return (
    // Provide the state values and state updater functions to children components
    <UserContext.Provider
      value={{
        setUser,
        user,
        isAuthenticated,
        setIsAuthenticated,
        onLoginUser,
        onLogoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
