import { Space, Typography, Divider } from 'antd';
import { userOutlined, welcomeLogo } from "assets";
import { PrimaryButton } from "globalComponents";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";


const WelcomePage = () => {
  const navigate = useNavigate();

  const handleNavigation = useCallback((to) => () => navigate(to), [navigate]);

  return (
    <main className="welcome-page-wrapper">
      <div className="wp-top">
        <Typography.Title level={5} className="welcome-text">
          Welcome To
        </Typography.Title>
        <img src={welcomeLogo} alt="" className="welcome-logo" />
        <Typography.Title
          level={1}
          className="font-bold text-black text-center"
        >
          Corsa University
        </Typography.Title>
      </div>
      <div className="wp-bottom" >
        <Space direction="vertical" size={30} className="wp-content">
          <Space direction="vertical">
            <Typography.Title level={5} className="text-black text-center title">
            Hey, Hello 👋
            </Typography.Title>
              <p className="details ">
              Welcome back! We are so happy to have you here. It's great to see you again.
We hope you had a safe and enjoyable time away.
              </p>
            <div className="auth-btn">
                 <button type="button" className="sign-up-btn"
                  onClick={handleNavigation("/auth/login")}
                >
                 Log In
                </button>
                <button type="button" className="sign-up-btn"
                  onClick={handleNavigation("/auth/register")}
                >
                  Sign Up
                </button>
            </div>
          </Space>


        </Space>
        <div className='bottom-bar' style={{width:'100%'}}>
              <div className="top-divider">
                <div className='divider'></div>
                <p>Or</p>
                <div className='divider'></div>
              </div>
              <div className="text-center copy-right">
             <mark className="trailer-mark font-bold"> Watch Trailer</mark>
            </div>
          </div>

      </div>
    </main>
  );
};

export default WelcomePage;
