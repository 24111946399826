import { CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";

const CustomCard = ({
  // index,
  // value,
  bandText,
  imgSrc,
  by,
  primaryText = "Primary Text",
  primaryTickColor,
  primaryFullWidth,
  secondaryText = "Secondary Text",
  secondaryTickColor,
  selected,
  arrowText,
  onArrowClick = null,
  onDelete,
  ...props
}) => {
  const band = bandText ? (
    <div className={"custom-card-band"}>
      <Typography.Paragraph className={"band-text"}>
        {bandText}
      </Typography.Paragraph>
    </div>
  ) : null;

  return (
    <div
      className={`custom-card-container ${
        selected ? "selected-custom-card-container" : ""
      } ${props.onClick ? "clickable" : ""}`}
      {...props}
    >
      {band}
      <div className={"image-container"}>
        <img
          className={"card-image un-draggable"}
          src={imgSrc}
          alt={"course"}
        />
      </div>
      <div className={`title-container ${primaryFullWidth ? "w-100-imp" : ""}`}>
        <Typography.Paragraph
          className={`title-text ${primaryFullWidth ? "w-100-imp" : ""}`}
        >
          {primaryText}
        </Typography.Paragraph>
        {primaryTickColor && <CheckCircleFilled className={primaryTickColor} />}
      </div>

      <div className={"desc-container"}>
        <Typography.Paragraph className={"desc-text"}>
          {by && <span className="by-text">{"By"}</span>} {secondaryText}
        </Typography.Paragraph>
        {secondaryTickColor && (
          <CheckCircleFilled className={`${secondaryTickColor} desc-icon`} />
        )}
      </div>
      <div className={"learn-more-container"}>
        <Typography.Paragraph
            style={{marginBottom: '10px', marginRight: '10px'}}
          className={"lm-text"}
          onClick={(e) => {
            e.stopPropagation();
            onArrowClick(props.item);
          }}
        >{`${arrowText} >`}</Typography.Paragraph>
      </div>
      {onDelete && (
        <Button
          icon={<DeleteOutlined />}
          size={"small"}
          className="delete-btn"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(props.item);
          }}
        />
      )}
    </div>
  );
};

export default CustomCard;
