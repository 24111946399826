import { MinusOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Input, Radio, Row, Slider, Typography } from "antd";
import { servicesImage } from "assets";
import { PrimaryButton } from "globalComponents";
import React from "react";

export const ServiceModalContent = () => {
  return (
    <div className="service-modal-container">
      <Row gutter={{ xs: 40, md: 104 }}>
        <Col xs={24} md={12}>
          <img className={"service-image"} src={servicesImage} alt="service" />
        </Col>
        <Col xs={24} md={12} style={{ padding: 10 }}>
          <Typography.Title className={"service-title"} level={4}>
            {"Fill Out Our Survey"}
          </Typography.Title>
          <Typography.Paragraph className={"service-text"}>
            {"How Many Posts"}
          </Typography.Paragraph>
          <Input className={"service-input"} placeholder={"how many posts"} />
          <Typography.Paragraph
            className={"service-text"}
            style={{ marginTop: 20 }}
          >
            {"Do you have a brand identity"}
          </Typography.Paragraph>
          <Radio.Group className={"service-radio"}>
            <Radio value={1}>{"Yes"}</Radio>
            <Radio value={2}>{"No"}</Radio>
            <Radio value={3}>{"Maybe"}</Radio>
          </Radio.Group>
          <Typography.Paragraph
            className={"service-text"}
            style={{ marginTop: 15 }}
          >
            {"Video to Image Ratio"}
          </Typography.Paragraph>
          <div className={"service-slider-container"}>
            <PrimaryButton style={{ background: "#1C1C1C" }}>
              <PlusOutlined />
            </PrimaryButton>
            <div className="slider-wrapper">
              <Slider />
            </div>
            <PrimaryButton style={{ background: "#1C1C1C" }}>
              <MinusOutlined />
            </PrimaryButton>
          </div>
          <PrimaryButton className={"service-submit-btn"}>
            <Typography.Paragraph className={"service-submit-text -mb-3-imp"}>
              {"Submit"}
            </Typography.Paragraph>
            <RightOutlined />
          </PrimaryButton>
        </Col>
      </Row>
    </div>
  );
};
