import { CheckCircleFilled } from '@ant-design/icons'
import { Avatar, Typography } from 'antd'
import { chatLockImage } from 'assets'
import { useEffect, useRef } from 'react'
import { dummyChat } from '../config/constants'
import { dummyImage } from 'assets/constants'

export const ChatListContainer = ({ locked }) => {
  const chatScrollRef = useRef(null)

  useEffect(() => {
    const div = chatScrollRef.current
    div.scrollTop = div.scrollHeight
  }, [chatScrollRef])
  return (
    <div className={`clc-container`}>
      <div className={'clc-gradient-overlay'} />
      <div className={'clc-list-container'} ref={chatScrollRef}>
        {locked ? (
          <LockedOverlay />
        ) : (
          dummyChat.map((chat, index) => <ChatItem key={index} chat={chat} />)
        )}
      </div>
    </div>
  )
}

const LockedOverlay = () => (
  <div className={'clc-lock-overlay'}>
    <img src={chatLockImage} alt={'lock'} />
  </div>
)

const ChatItem = ({ chat }) => {
  const { text, from, premium } = chat
  return (
    <div
      className={`chat-item-container ${
        from === 'your username' ? 'user-chat-item-container' : ''
      }`}
    >
      {from === 'your username' ? (
        <ChatRight text={text} />
      ) : (
        <ChatLeft
          avatar={dummyImage}
          text={text}
          from={from}
          premium={premium}
          time={'2 mints ago'}
        />
      )}
    </div>
  )
}

const ChatLeft = ({ avatar, from, text, premium, time }) => {
  return (
    <div className={`chat-item-left-container ${premium ? 'premium-chat' : ''}`}>
      <div className={'item-left-header'}>
        <div className={'item-avatar-text'}>
          <Avatar src={avatar} className={'item-avatar'} alt={'avatar'} />
          <Typography.Paragraph className={'item-username -mb-3-imp'}>{from}</Typography.Paragraph>
          {premium ? <CheckCircleFilled className={'item-checked'} /> : null}
        </div>
        <Typography.Paragraph className={'item-time-text -mb-3-imp'}>{time}</Typography.Paragraph>
      </div>
      <div className={'item-left'}>
        <Typography.Text className={'chat-text'}>{text}</Typography.Text>
      </div>
    </div>
  )
}

const ChatRight = ({ text }) => {
  return (
    <div className={'chat-item-right-container'}>
      <div className={'item-right'}>
        <Typography.Text className={'chat-text'}>{text}</Typography.Text>
      </div>
    </div>
  )
}
