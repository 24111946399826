import { useCallback, useMemo, useState } from "react";
import { MyInfo, MyProjects, MySubscriptions } from "../";

const useAccountTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = useMemo(
    () => [
      {
        id: 1,
        title: "My Info",
        component: <MyInfo />,
        icon: <></>,
      },
      {
        id: 2,
        title: "My Projects",
        component: <MyProjects />,
        icon: <></>,
      },
      {
        id: 3,
        title: "My Subscriptions",
        component: <MySubscriptions />,
        icon: <></>,
      },
    ],
    []
  );

  const onTabClick = useCallback((index) => () => setActiveTabIndex(index), []);

  return { activeTabIndex, onTabClick, tabs };
};

export default useAccountTabs;
