import { Spin, Typography } from "antd";
import { arrowRightWhite } from "assets";
import { useMemo } from "react";

const StepsProgress = ({ activeStep, total, onNextStep, loading }) => {
  const width = useMemo(
    () => `${Math.round(((activeStep + 1) / total) * 100)}%`,
    [activeStep, total]
  );

  return (
    <div className="steps-progress-wrapper">
      {loading ? (
        <Spin className="progress-spinner" />
      ) : (
        <>
          <div
            className={`active-step step-${activeStep}`}
            style={{ width }}
            disabled={loading}
            onClick={onNextStep}
          >
            <Typography.Title level={5} className="m-0 font-bold">
              Next
            </Typography.Title>
          </div>
          <Typography.Title level={5} className="m-0 font-bold steps-text">{`${
            activeStep + 1
          }/${total}`}</Typography.Title>
          <img src={arrowRightWhite} alt="" className="arrow-right" />{" "}
        </>
      )}
    </div>
  );
};

export default StepsProgress;
