import { APP_LOGO } from "assets";
import "./styles.scss";

const Loader = ({ overlay = false, pageLoader = false }) => {
  return (
    <div className={`loader-wrapper ${overlay ? "overlay" : ""}`}>
      {pageLoader && <img alt="page-loader" src={APP_LOGO} />}
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
