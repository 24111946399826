import { Avatar, Typography } from 'antd'
import { checkCurlyGoldImage } from 'assets'
import { dummyImage } from 'assets/constants'
import { PrimaryButton } from 'globalComponents'
export const AffiliateHeader = () => {
  return (
    <div className={'ah-header-container'}>
      <div className={'ah-avatar-text'}>
        <Avatar className={'ah-avatar'} src={dummyImage} alt={'avatar'} />
        <div className={'ah-user-details'}>
          <div className={'ah-username'}>
            <Typography.Title style={{ color: "black"}} className={'username'} level={3}>
              {'Younivision Ltd'}
            </Typography.Title>
            <img className='ah-image' src={checkCurlyGoldImage} alt={'check'} />
          </div>
          <Typography.Paragraph className={'ah-package-text'}>{'Gold'}</Typography.Paragraph>
        </div>
      </div>
      <PrimaryButton text={'My Settings'} style={{ width: 191 }} />
    </div>
  )
}
