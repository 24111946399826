import { useForm } from "antd/es/form/Form";
import { addInstructorApiAxiosParams } from "api/useAxiosParams";
import { useAxios } from "hooks";
import { useCallback, useMemo, useRef, useState } from "react";
import { MentorDetailsForm, MentorSocialLinksForm } from "./components";

const useManageMentorModal = ({ onCancel }) => {
  const [form] = useForm();
  const [formValues, setFormValues] = useState({});
  const formRefs = useRef([]);

  const { loading, callAxios: callAddInstructorApi } = useAxios(
    addInstructorApiAxiosParams
  );

  const [activeStep, setActiveStep] = useState(0);

  const stepsComponent = useMemo(
    () => [
      <MentorDetailsForm ref={(ref) => (formRefs.current[0] = ref)} />,
      <MentorSocialLinksForm ref={(ref) => (formRefs.current[1] = ref)} />,
    ],
    []
  );

  const handleAddInstructor = useCallback(
    async (formValues) => {
      try {
        const formData = new FormData();
        Object.keys(formValues).forEach((key) => {
          formData.append(key, formValues[key]);
        });
        formData.append(
          "picture",
          formValues.picture?.fileList[0]?.originFileObj
        );

        const result = await callAddInstructorApi({
          data: formData,
        });
        if (result?.success) {
          setActiveStep(0);
          setFormValues({});
          onCancel({ success: true });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [callAddInstructorApi, onCancel]
  );

  const onNextStep = async () => {
    try {
      const result = await formRefs.current[activeStep].triggerSubmit();
      if (activeStep === 1) {
        return handleAddInstructor({ ...result, ...formValues });
      } else {
        setFormValues((curr) => ({ ...curr, ...result }));
        setActiveStep((curr) => (curr < 1 ? curr + 1 : curr));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    handleAddInstructor,
    loading,
    form,
    activeStep,
    onNextStep,
    stepsComponent,
  };
};

export default useManageMentorModal;
