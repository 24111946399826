import {
  addCourseApiAxiosParams,
  getInstructorsApiAxiosParams,
} from "api/useAxiosParams";
import { useAxios } from "hooks";
import { useMemo, useRef, useState } from "react";
import {
  AddCourseContent,
  AddNewCourse,
  FinaliseCourseContent,
} from "./components";

const useManageCourseModal = ({ onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);
  const formRefs = useRef([]);
  const [formValues, setFormValues] = useState({});
  const progressRef = useRef();

  const { result: instructorsApiResult, loading: getInstructorsApiLoading } =
    useAxios(getInstructorsApiAxiosParams);

  const { loading: addCourseApiLoading, callAxios: callAddCourseApi } =
    useAxios(addCourseApiAxiosParams);

  const instructorOptions = useMemo(
    () =>
      instructorsApiResult?.data
        ? instructorsApiResult?.data.map((user) => ({
            label: user?.instructors?.name,
            value: user?.instructors?.id,
          }))
        : [],

    [instructorsApiResult]
  );

  const finalInfo = useMemo(
    () => ({
      ...formValues,
      instructorName: instructorOptions
        ? instructorOptions.find(
            (instructor) => instructor.value === formValues?.instructors_id
          )?.label
        : "",
    }),
    [formValues, instructorOptions]
  );

  const stepsComponent = useMemo(
    () => [
      <AddNewCourse
        ref={(ref) => (formRefs.current[0] = ref)}
        getInstructorsApiLoading={getInstructorsApiLoading}
        instructorOptions={instructorOptions}
      />,
      <AddCourseContent ref={(ref) => (formRefs.current[1] = ref)} />,
      <FinaliseCourseContent
        ref={(ref) => (formRefs.current[2] = ref)}
        finalInfo={finalInfo}
      />,
    ],
    [getInstructorsApiLoading, instructorOptions, finalInfo]
  );

  const onNextStep = async () => {
    try {
      if (activeStep === 2) {
        const formData = new FormData();
        formData.append("name", formValues.name);
        formData.append("description", formValues.description);
        formData.append("instructors_id", formValues.instructors_id);
        formData.append("price", formValues.price);
        formData.append(
          "thumbnail",
          formValues?.thumbnail?.fileList[0]?.originFileObj
        );

        formValues.modules.forEach((module, moduleIndex) => {
          formData.append(`modules[${moduleIndex}].name`, module.name);
          formData.append(
            `modules[${moduleIndex}].description`,
            module.description
          );
          module.lessons.forEach((lesson, lessonIndex) => {
            formData.append(
              `modules[${moduleIndex}].lessons[${lessonIndex}].name`,
              lesson.name
            );
            lesson.lessonFile.fileList.forEach((file, fileIndex) => {
              formData.append(
                `modules[${moduleIndex}].lessons[${lessonIndex}].file[${fileIndex}]`,
                file.originFileObj
              );
            });
          });
        });
        const response = await callAddCourseApi({ data: formData });
        if (response?.success) {
          setActiveStep(0);
          setFormValues({});
          onCancel({ success: true });
          return;
        }
      } else {
        const result = await formRefs.current[activeStep].triggerSubmit();
        setFormValues((curr) => ({ ...curr, ...result }));
        setActiveStep((curr) => (curr < 2 ? curr + 1 : curr));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    progressRef,
    activeStep,
    onNextStep,
    stepsComponent,
    formRefs,
    addCourseApiLoading,
  };
};

export default useManageCourseModal;
