const API_ENDPOINTS = {
  REGISTER: `authentication/register`,
  LOGIN: `authentication/login`,
  TOKEN_VALIDITY: "authentication/token-validity",
  DELETE_USER: (userId) => `user/remove-user?userId=${userId}`,

  GET_COURSES: "course/get-courses",
  GET_COURSES_BY_INSTRUCTOR_ID: (instructorId) =>
    `course/get-instructorCourses?instructorId=${instructorId}`,
  GET_COURSE_DETAIL: (courseId) => `course/get-course?courseId=${courseId}`,
  ADD_COURSE: "course/add-course",

  ADD_INSTRUCTOR: "user/add-instructor",
  GET_INSTRUCTOR_DETAIL: (userId) => `user/get-instructorById?userId=${userId}`,
  GET_INSTRUCTORS: "user/get-instructors",

  GET_SERVICES: "service/get-services",
  ADD_SERVICE: "service/add-service",

  GET_INSTRUCTOR_SCHEDULE: (instructorId, currentDateTime) =>
    `/schedule/get-schedules?instructorId=${instructorId}&currentDateTime=${currentDateTime}`,

  GET_ADMIN_DASHBOARD: `user/get-dashboard`,

  BOOK_SLOT: "schedule/add-schedule",
};

export default API_ENDPOINTS;
