import useUser from "context/user/useUser";
import { Loader } from "globalComponents";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import getRoutes from "./routes";
import {useEffect, useState} from "react";
import userProvider from "../context/user/userProvider";

const RouterConfig = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // userProvider.signOutUser()
    const fetchedUser = userProvider.getCurrentUser();
    fetchedUser ? setIsAuthenticated(true) : setIsAuthenticated(false)
  },[]);

  return (
      <RouterProvider router={createBrowserRouter(getRoutes(isAuthenticated))} />
  );

  // return isAuthenticated === null ? (
  //   <Loader overlay pageLoader/>
  // ) : (
  //   <RouterProvider router={createBrowserRouter(getRoutes(isAuthenticated))} />
  // );
};

export default RouterConfig;
