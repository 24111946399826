import { Grid, Modal } from "antd";
import "./styles.scss";

const { useBreakpoint } = Grid;
const CustomModal = ({ open, onCancel, children, ...rest }) => {
  const { sm, md } = useBreakpoint();
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      centered
      closeIcon={<div />}
      wrapClassName={"custom-modal"}
      footer={false}
      width={md ? 760 : sm ? 600 : 500}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
