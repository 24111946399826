import { WechatOutlined } from '@ant-design/icons'
import { Drawer, Grid } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Chat } from 'features'
import { useEffect, useState } from 'react'
import './styles.scss'

const ChatLayout = () => {
  const { useBreakpoint } = Grid
  const { xl, lg, md } = useBreakpoint() ?? {}
  const [showChat, setShowChat] = useState(false)

  const toggleChat = () => setShowChat(!showChat)
  useEffect(() => {
    if (md) setShowChat(false)
  }, [md])

  if (md === undefined) return null

  return (
    <>
      {!md && (
        <div className={`cl-open-chat ${showChat ? 'cl-close-chat' : ''}`} onClick={toggleChat}>
          <WechatOutlined />
        </div>
      )}
      <Sider width={xl ? 320 : lg ? 285 : md ? 250 : 0} className={'chat-sidebar-container'}>
        <Chat />
      </Sider>
      <Drawer
        open={showChat}
        placement={'right'}
        closable={false}
        onClose={toggleChat}
        destroyOnClose
        width={'90%'}
        style={{
          maxWidth: 320,
        }}
        rootClassName={'chat-sidebar-drawer'}
      >
        <Chat />
      </Drawer>
    </>
  )
}

export default ChatLayout
