import React from "react";
import "./styles.scss";
import { Button, Typography } from "antd";
import { MentorshipList } from "./components/MentorshipList";
import useMentorship from "./useMentorship";
import ManageMentorModal from "./components/ManageMentorModal";
import { Loader } from "globalComponents";

const MentorshipPage = () => {
  const {
    toggleManageMentorModalVisibility,
    isManageMentorModalVisible,
    instructors,
    getInstructorsApiLoading,
    handleInstructorDelete,
    messageContextHolder,
  } = useMentorship();

  return (
    <>
      <ManageMentorModal
        open={isManageMentorModalVisible}
        onCancel={toggleManageMentorModalVisibility}
      />
      {messageContextHolder}
      <div className={"instructors-page-container cards-main-container"}>
        <div className={"cards-title"}>
          <Typography.Title className={"title"} level={4}>
            {"Our Instructors"}
          </Typography.Title>
          <Button type="primary" onClick={toggleManageMentorModalVisibility}>
            Add
          </Button>
        </div>
        {getInstructorsApiLoading ? (
          <Loader />
        ) : (
          <MentorshipList
            handleInstructorDelete={handleInstructorDelete}
            instructors={instructors}
          />
        )}
      </div>
    </>
  );
};

export default MentorshipPage;
