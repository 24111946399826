import useMessage from "antd/es/message/useMessage";
import API_ENDPOINTS from "api/apiEndpoints";
import { getInstructorScheduleAxiosParams } from "api/useAxiosParams";
import dayjs from "dayjs";
import { useAxios } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { bookSlotAxiosParams } from "api/useAxiosParams";

dayjs.extend(weekday);
dayjs.extend(localeData);

const useAppointment = () => {
  const [messageApi, messageContext] = useMessage();
  const { instructorId } = useParams();
  const {
    callAxios: callGetInstructorScheduleApi,
    loading: getInstructorScheduleApiLoading,
    cancelRequest: cancelGetInstructorScheduleApi,
    result: instructorScheduleResult,
  } = useAxios(getInstructorScheduleAxiosParams);

  const { loading: bookSlotApiLoading, callAxios: callBookSlotApi } =
    useAxios(bookSlotAxiosParams);

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedSlots, setSelectedSlots] = useState([]);

  const disabledDate = (current) => current.isBefore(dayjs(), "day");
  const isBookButtonDisabled = useMemo(
    () => !selectedSlots.length,
    [selectedSlots]
  );

  const handleSlotSelect = (val) =>
    setSelectedSlots((curr) => {
      const currCopy = [...curr];
      const foundedIndex = currCopy.findIndex((currItem) => currItem === val);
      if (foundedIndex > -1) {
        currCopy.splice(foundedIndex, 1);
      } else {
        currCopy.push(val);
      }
      return currCopy;
    });

  const isSlotSelected = useCallback(
    (val) => selectedSlots.includes(val),
    [selectedSlots]
  );
  const getInstructorSchedule = useCallback(async () => {
    setSelectedSlots([]);
    await callGetInstructorScheduleApi({
      url: API_ENDPOINTS.GET_INSTRUCTOR_SCHEDULE(
        instructorId,
        selectedDate.toISOString()
      ),
    });
  }, [callGetInstructorScheduleApi, instructorId, selectedDate]);

  const handleDateChange = (current) => {
    setSelectedDate(current);
  };

  const handleBooking = async () => {
    const data = {
      start: selectedSlots,
      instructors_id: instructorId,
    };
    const result = await callBookSlotApi({
      data,
    });

    if (result?.success) {
      setSelectedSlots([]);
      getInstructorSchedule();
    }
  };

  useEffect(() => {
    getInstructorSchedule();
    return () => cancelGetInstructorScheduleApi();
  }, [selectedDate, getInstructorSchedule, cancelGetInstructorScheduleApi]);

  useEffect(() => {
    if (getInstructorScheduleApiLoading)
      messageApi.loading("Fetching Schedule");
    return () => messageApi.destroy();
  }, [getInstructorScheduleApiLoading, messageApi]);

  return {
    selectedDate,
    handleDateChange,
    messageContext,
    disabledDate,
    schedules: instructorScheduleResult?.data || [],
    handleSlotSelect,
    isSlotSelected,
    isBookButtonDisabled,
    handleBooking,
    bookSlotApiLoading,
  };
};

export default useAppointment;
