import { Col, Form, Input, Radio, Row, Space, Typography } from "antd";
import { INSTRUCTOR_VERIFICATION } from "constants/common";
import { ImagePicker } from "globalComponents";
import { formRules } from "./formRules";
import { forwardRef, useImperativeHandle } from "react";
import { useForm } from "antd/es/form/Form";

const MentorDetailsForm = (_, ref) => {
  const [form] = useForm();

  useImperativeHandle(
    ref,
    () => ({
      triggerSubmit: () => form.validateFields(),
    }),
    [form]
  );
  return (
    <Form layout="vertical" className="form-style-1" form={form}>
      <Row className="h-100" gutter={[10, 20]}>
        <Col xs={24} sm={11}>
          <ImagePicker
            title={"Add Profile Picture"}
            description={"1204 x 1204"}
            name={"picture"}
            rules={formRules.picture}
          />
        </Col>
        <Col className="h-100" xs={24} sm={13}>
          <Space>
            <Typography.Title className="font-bold" level={4}>
              Add An Instructor
            </Typography.Title>
          </Space>

          <Form.Item label="Email" rules={formRules.email} name="email">
            <Input
              className="input-style-1"
              type="email"
              autoComplete="false"
            />
          </Form.Item>
          <Row gutter={[10]}>
            <Col xs={12}>
              <Form.Item
                label="Username"
                rules={formRules.usernmae}
                name="username"
              >
                <Input className="input-style-1" autoComplete="false" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Password"
                rules={formRules.password}
                name="password"
              >
                <Input.Password className="input-style-1" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Description"
            rules={formRules.description}
            name="description"
          >
            <Input className="input-style-1" />
          </Form.Item>
          <Form.Item
            label="Verification"
            name={"verification"}
            rules={formRules.verification}
          >
            <Radio.Group className="radio-text-white">
              {Object.values(INSTRUCTOR_VERIFICATION).map((value) => (
                <Radio value={value} key={value}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(MentorDetailsForm);
